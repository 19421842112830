import { Autocomplete, Box, TextField } from '@mui/material'
import DateRangePicker from './DateRangePicker'
import SearchBox from './SearchBox'
import { ActionBarPropsType } from './ProjectSheetTypes'
import { style } from './SheetStyles'
import ExportButton from './ExportButton'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

const ActionBar = (props: any) => {
  const { state } = useLocation()
  const {
    searchQuery,
    selectedTab,
    setSearchQuery,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setShowResults,
    subTableData,
    setWorkingEmployee,
    workingEmployee,
    employeeRecords,
  } = props
  const [value, setValue] = useState({})

  useEffect(() => {
    if (workingEmployee) setValue(workingEmployee)
  }, [workingEmployee])

  return (
    <Box component='div' id='actionBar'>
      <Box sx={style.actionBarConatiner} paddingBottom={'0'}>
        <Box
          sx={state?.type === 'productivitymatrix' ? style.actionBarProductivity : style.actionBar}
        >
          {state?.type !== 'productivitymatrix' && (
            <SearchBox searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          )}
          {state?.type === 'productivitymatrix' && (
            <Autocomplete
              autoFocus={false}
              size='small'
              disablePortal
              id='select-working-employees'
              options={employeeRecords}
              getOptionLabel={(option: any) => option?.info ?? ''}
              // getOptionDisabled={(option: any) => option?.userId === 1806}
              sx={{
                width: '240px',
                '.MuiInputBase-root': {
                  padding: '22px 11px',
                  borderRadius: '20px',
                  fontSize: '13px',
                  fontFamily: 'Montserrat-Medium',
                  height: '45px',
                },
                '& .MuiFormControl-root': {
                  margin: '0',
                  marginTop: '5px',
                },
                '& .MuiFormLabel-root ': {
                  backgroundColor: 'white',
                  width: '165px',
                },
                '.MuiFormLabel-asterisk': {
                  color: 'white',
                },
              }}
              // clearOnEscape={false}
              // clearIcon={null}
              renderInput={(params: any) => (
                <TextField {...params} label='Select Employee' variant='outlined' />
              )}
              onChange={(event: any, newValue: any) => {
                // Check if newValue exists and has an 'info' property before calling extractRole
                if (newValue && newValue?.EmployeeName) {
                  setWorkingEmployee(newValue?.EmployeeName)
                  setValue(newValue)
                } else {
                  setWorkingEmployee('') // Clear or handle case when no employee is selected
                  setValue({})
                }
              }}
              ListboxProps={{
                style: {
                  maxHeight: '200px',
                },
              }}
            />
          )}
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            padding='0'
            sx={style.datePickerContainer}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowResults={setShowResults}
              searchQuery={searchQuery}
              selectedTab={selectedTab}
            />
          </Box>
          {state?.type !== 'productivitymatrix' && (
            <Box sx={style.actionBarDownloadbtn}>
              <ExportButton
                subTableData={subTableData}
                selectedTab={selectedTab}
                startDate={startDate}
                endDate={endDate}
                searchQuery={searchQuery}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ActionBar
