import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import './IDSR.scss'
import PendingIcon from '@mui/icons-material/Pending'
import CheckIcon from '@mui/icons-material/Check'
import priority from './constant'
import { ReactComponent as EditIcon } from '../../assets/images/editIconTimesheet.svg'
import { ReactComponent as CheckedApprovedIcon } from '../../assets/images/checkApprovedIcon.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DraftsIcon from '@mui/icons-material/Drafts'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Modal,
  Tooltip,
  InputAdornment,
  Autocomplete,
} from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core'
import {
  HeaderHeading,
  StyledMenuItem,
  StyledTableCell,
  StyledTableCellForMyTeam,
  StyledTableRow,
  loaderProps,
} from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { useEffect, useState, useCallback, useRef } from 'react'
import {
  createNewPlanForTheDay,
  createNewIDSR,
  fetchProjectsName,
  getAttendanceTimesheet,
  getIDSRs,
  getPlans,
  getStatusType,
  getTaskStatus,
  fetchMandateType,
  getPlanForTheDay,
  editPlanForTheDay,
  getSingleIdsr,
  editSingleIdsr,
  createNewRCA,
  getRCAs,
  fetchIdsrRecent,
} from '../../actions'
import {
  attendanceEntity,
  attendanceUI,
  dashboardEntity,
  employeePortalEntity,
  employeePortalUI,
  projectManagementEntity,
} from '../../reducers'
import {
  fetchPlans,
  fetchIDSRs,
  fetchRCAs,
  fetchStatusType,
  employeeDataRequest,
} from '../../reducers/entities'
import { Heading } from '../Pages/Styles'
import CloseIcon from '@mui/icons-material/Close'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Grow from '@mui/material/Grow'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CommentIcon from '@mui/icons-material/Comment'
import { Primary } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import ReactDOMServer from 'react-dom/server'
import RcaFormat from '../Rca/RcaFormat'
import dayjs from 'dayjs'
interface IFormValues {
  projectName: string
  otherProjectName: string
  timeSpend: string
  taskDescription: string
  mandateId: string
  no_of_pr_raised?: number | null
  no_of_pr_approved?: number | null
  no_of_reworked_pr?: number | null
  isYesterdayTask?: boolean
  statusId?: string
  priority?: string
}
const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  marginTop: '25px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '70px',
  border: '1px solid #DDDDDD',
}))

const StyledFormControl = styled(FormControl)(() => ({
  marginBottom: '10px',
  marginTop: '6px',
}))

const ActionButton = styled(Button)(() => ({
  fontSize: '13px',
  height: '42px',
  borderRadius: '20px',
  padding: '5px 20px',
  fontFamily: 'Montserrat-Medium',
  marginLeft: '1rem',
}))

const CustomFormControll = styled(FormControl)(() => ({
  marginLeft: '1px',
  marginTop: '5px',
}))

const InputField = styled(TextField)(() => ({
  marginTop: '8px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))
const InputField1 = styled(TextField)(() => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'white',
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    fontSize: '15px',
    lineHeight: '1.8em',
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface ICancelButton {
  fontSize?: string
  height?: string
}

const CancelButton = styled(Button)(({ fontSize = '13px', height = '42px' }: ICancelButton) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: `${fontSize}`,
  height: `${height}`,
  fontFamily: 'Montserrat-SemiBold',
  width: '100px',
  borderRadius: '20px',
  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
  },
}))

const SelectField = styled(Select)(() => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '8px 14px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
  },
}))
const StyledSelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '9px 11px',
    fontSize: '13px',
    fontFamily: 'Montserrat-Medium',
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
    padding: '9px 11px',
    marginLeft: '-1px',
    fontSize: '13px',
  },
}))

const IDSRTable = (props: any) => {
  const {
    setSingleIdsrId,
    setCheckIdsrForm,
    getSingleIdsr,
    isFetchingIDSRs,
    getStatusType,
    getTaskStatus,
    mandateData,
    getMandateType,
    setisNewIDSRDialogOpen,
    isUpdatedSingleIdsrList,
    isUpdateLoaderSingleIdsrList,
  } = props

  let statusType = new Map<number, string>()

  useEffect(() => {
    getMandateType({
      data: { pageSize: '', search: '', page: '' },
    })
  }, [])

  mandateData?.data?.forEach((mandate: { id: number; mandate_name: string }) => {
    statusType.set(mandate.id, mandate.mandate_name)
  })

  let taskStatus = new Map<number, string>()

  for (const key in getTaskStatus) {
    taskStatus.set(getTaskStatus[key]?.id, getTaskStatus[key]?.status_name)
  }

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  const leadTooltipContent = (data: any) => {
    return (
      <div style={{ padding: '8px', width: '150px' }}>
        <Typography fontWeight={500} fontSize={'13px'}>
          Comment:{' '}
        </Typography>
        <Typography marginTop={'5px'} fontSize={'12px'}>
          {data}
        </Typography>
      </div>
    )
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  const handleEditIDSR = (event: any, data: any) => {
    const dataPfp = data.id
    event.preventDefault()
    event.stopPropagation()
    setSingleIdsrId(dataPfp)
    setCheckIdsrForm('edit')
    setisNewIDSRDialogOpen(true)

    getSingleIdsr({ idsrId: dataPfp })
  }

  return (
    <>
      <Dialog
        open={isFetchingIDSRs || isUpdateLoaderSingleIdsrList}
        PaperProps={{ style: loaderProps }}
      >
        <CircularProgress color='secondary' />
      </Dialog>
      <>
        <TableContainer sx={{ margin: '1rem 0' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>DATE (DAY)</StyledTableCell>
                <StyledTableCell style={{ width: '90px' }}>SUBJECT</StyledTableCell>
                <StyledTableCell style={{ width: '110px' }}>PROJECT NAME</StyledTableCell>
                <StyledTableCell>TYPE</StyledTableCell>
                <StyledTableCell>STATUS</StyledTableCell>
                <StyledTableCell style={{ width: '90px' }}>TIME SPENT</StyledTableCell>
                <StyledTableCell style={{ width: '130px' }}>SUBMITTED AT</StyledTableCell>
                <StyledTableCell>COMMENT</StyledTableCell>
                <StyledTableCell>ACTION</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data?.length ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => props.onRowClick(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledTableCellForMyTeam component='th' scope='row'>
                      {`${new Date(data.IDSR_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}`}{' '}
                      (
                      {`${new Date(data.IDSR_date).toLocaleDateString('en-GB', {
                        weekday: 'long',
                      })}`}
                      )
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>{data.subject}</StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {data.mandate_type_id ? statusType.get(data.mandate_type_id) : '-'}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {data.status_id ? taskStatus.get(data.status_id) : '-'}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {`${data.time_spent} mins (${convertMinutesToHours(data.time_spent)})`}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {data.is_draft === 1 ? '-' : dayjs(data.submission_time).format('hh:mm A')}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      <Tooltip
                        placement='left'
                        classes={{
                          tooltip: 'custom-tooltip',
                          arrow: 'custom-tooltip-arrow',
                        }}
                        title={data.comment ? leadTooltipContent(htmlToText(data.comment)) : ''}
                        arrow
                      >
                        <IconButton
                          sx={{
                            width: '40px',
                            opacity: data?.comment ? 1 : 0.5,
                            color: '#483F3F',
                            cursor: data?.comment ? 'pointer' : 'not-allowed',
                            marginTop: '6px',
                          }}
                          disableRipple={data?.comment}
                        >
                          {' '}
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '6px',
                          justifyContent: 'space-between',
                        }}
                      >
                        {Number(data.is_draft) === 1 && (
                          <Tooltip title='Edit' arrow>
                            <EditIcon
                              onClick={(event) => handleEditIDSR(event, data)}
                              width={'25px'}
                              height={'25px'}
                            />
                          </Tooltip>
                        )}

                        {Number(data.is_draft) === 1 ? (
                          <Tooltip title={'Drafted'} arrow>
                            <DraftsIcon
                              width={'25px'}
                              height={'25px'}
                              style={{ color: 'orange' }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title={'Submitted'} arrow>
                            <CheckedApprovedIcon style={{ width: '25px', height: '25px' }} />
                          </Tooltip>
                        )}
                      </Box>
                    </StyledTableCellForMyTeam>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCellForMyTeam align='center' colSpan={10}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                      No matching records found.
                    </Typography>
                  </StyledTableCellForMyTeam>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </>
    </>
  )
}

const PlanForTheDayTable = (props: any) => {
  const {
    isFetchingPlans,
    getStatusType,
    mandateData,
    setNewPlanDialog,
    getPfpId,
    setPfpId,
    setCheckPfpForm,
    getPlanForTheDay,
    isUpdateLoaderPlanForTheDayList,
    setCcData,
  } = props

  let statusType = new Map<number, string>()

  mandateData?.data?.forEach((mandate: { id: number; mandate_name: string }) => {
    statusType.set(mandate.id, mandate.mandate_name)
  })

  const handleEditPlanForTheDay = (event: any, data: any) => {
    setCcData(data.cc)
    const dataPfp = data.id
    event.preventDefault()
    event.stopPropagation()
    setCheckPfpForm('edit')
    setPfpId(dataPfp)
    setNewPlanDialog(true)
    getPlanForTheDay({ planforthedayId: dataPfp })
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  const leadTooltipContent = (data: any) => {
    return (
      <div style={{ padding: '8px', width: '150px' }}>
        <Typography fontWeight={500} fontSize={'13px'}>
          Comment:{' '}
        </Typography>
        <Typography marginTop={'5px'} fontSize={'12px'}>
          {data}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Dialog
        open={isFetchingPlans || isUpdateLoaderPlanForTheDayList}
        PaperProps={{ style: loaderProps }}
      >
        <CircularProgress color='secondary' />
      </Dialog>
      <>
        <TableContainer sx={{ margin: '1rem 0' }} component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>DATE (DAY)</StyledTableCell>
                <StyledTableCell style={{ width: '90px' }}>SUBJECT</StyledTableCell>
                <StyledTableCell style={{ width: '110px' }}>PROJECT NAME</StyledTableCell>
                <StyledTableCell>TYPE</StyledTableCell>
                <StyledTableCell>EST TIME</StyledTableCell>
                <StyledTableCell style={{ width: '110px' }}>SUBMITTED AT</StyledTableCell>
                <StyledTableCell>COMMENT</StyledTableCell>
                <StyledTableCell>ACTION</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {props.data?.length ? (
              <TableBody>
                {props.data.map((data: any, index: number) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => props.onRowClick(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledTableCellForMyTeam component='th' scope='row'>
                      {`${new Date(data.PLANFORDAY_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}`}{' '}
                      (
                      {`${new Date(data.PLANFORDAY_date).toLocaleDateString('en-GB', {
                        weekday: 'long',
                      })}`}
                      )
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>{data.subject}</StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {data.project_name === 'Other' ? data.other_project_name : data.project_name}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {data.mandate_type_id ? statusType.get(data.mandate_type_id) : '-'}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {`${data.time_spent} mins (${convertMinutesToHours(data.time_spent)})`}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      {dayjs(data.submission_time).format('hh:mm A')}
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='center'>
                      <Tooltip
                        placement='left'
                        classes={{
                          tooltip: 'custom-tooltip',
                          arrow: 'custom-tooltip-arrow',
                        }}
                        title={data.comment ? leadTooltipContent(htmlToText(data.comment)) : ''}
                        arrow
                      >
                        <IconButton
                          sx={{
                            width: '40px',
                            opacity: data?.comment ? 1 : 0.5,
                            color: '#483F3F',
                            cursor: data?.comment ? 'pointer' : 'not-allowed',
                            marginTop: '6px',
                          }}
                          disableRipple={data?.comment}
                        >
                          {' '}
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCellForMyTeam>
                    <StyledTableCellForMyTeam align='left'>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginTop: '10px',
                        }}
                      >
                        {data.approve_status === 1 ? (
                          <Tooltip title={'Approved'} arrow>
                            <CheckedApprovedIcon
                              fontSize='small'
                              style={{ width: '27px', height: '27px', textAlign: 'center' }}
                            />
                          </Tooltip>
                        ) : data.approve_status === 0 ? (
                          <>
                            <Tooltip title={'Edit'} arrow>
                              <EditIcon
                                onClick={(event) => handleEditPlanForTheDay(event, data)}
                                style={{ fontSize: '32px' }}
                                width={'27px'}
                                height={'27px'}
                              />
                            </Tooltip>
                            <Tooltip title={'Pending'} arrow>
                              <PendingIcon
                                style={{ marginLeft: '10px', color: 'orange', fontSize: '33px' }}
                                width={'27px'}
                                height={'27px'}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          ''
                        )}
                      </Box>
                    </StyledTableCellForMyTeam>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCellForMyTeam align='center' colSpan={10}>
                    <Typography variant='subtitle1' sx={{ color: '#483f3f', marginLeft: 50 }}>
                      No matching records found.
                    </Typography>
                  </StyledTableCellForMyTeam>
                </StyledTableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </>
    </>
  )
}

const CreateIDSRDialog = ({
  getPlanForTheDayData,
  open,
  onClose,
  createIDSR,
  AllProjectsName,
  getStatusType,
  getTaskStatus,
  getManagerDetails,
  mandateData,
  setCheckIdsrForm,
  checkIdsrForm,
  getSingleIdsr,
  editSingleIdsr,
  resetEditSingleIdsr,
  resetGetSingleIdsr,
  getSingleIdsrData,
  isGetSingleIdsrList,
  isUpdatedSingleIdsrList,
  isUpdateLoaderSingleIdsrList,
  singleIdsrId,
  userId,
  fetchAttendanceData,
  selectedMonthId,
  getAllPlansList,
  getPlanForTheDay,
  employeeRecords,
}: {
  getPlanForTheDay: any
  getAllPlansList: any
  getPlanForTheDayData: any
  mandateData: any
  open: any
  onClose: () => void
  createIDSR: (data: any) => void
  AllProjectsName: string[]
  getStatusType: [{ id: number; type_name: string }]
  getTaskStatus: [{ id: number; status_name: string }]
  getManagerDetails: { email: string; name: string }
  setCheckIdsrForm: any
  checkIdsrForm: string
  getSingleIdsr: any
  editSingleIdsr: any
  resetEditSingleIdsr: any
  resetGetSingleIdsr: any
  isGetSingleIdsrList: any
  isUpdatedSingleIdsrList: any
  isUpdateLoaderSingleIdsrList: any
  getSingleIdsrData: any
  singleIdsrId: any
  userId: any
  fetchAttendanceData: any
  selectedMonthId: any
  employeeRecords: any
}) => {
  const [to, setTo] = useState(getManagerDetails?.email || '')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)
  const [cc, setCc] = useState('')
  const [workWith, setWorkWith] = useState<any>('')
  const [formValues, setFormValues] = useState<IFormValues[]>([
    {
      projectName: '',
      otherProjectName: '',
      timeSpend: '',
      taskDescription: '',
      statusId: '2',
      priority: 'High',
      mandateId: String(mandateData?.data?.[0]?.id),
      no_of_pr_raised: 0,
      no_of_pr_approved: 0,
      no_of_reworked_pr: 0,
    },
  ])

  useEffect(() => {
    if (getSingleIdsrData?.data?.length > 0 && checkIdsrForm === 'edit') {
      setWorkWith(
        employeeRecords?.find((value: any) => value?.userId === getSingleIdsrData?.workWith) ?? '',
      )

      const updatedFormValues = getSingleIdsrData?.data?.map((item: any) => ({
        projectName: AllProjectsName?.includes(item.projectName) ? item.projectName : 'Other',
        otherProjectName: item.otherProjectName || '',
        timeSpend: item.timeSpend || '',
        taskDescription: convertHTMLToPlainText(item.taskDescription) || '',
        priority: item.priority || 'High',
        statusId: String(item.statusId) || '2',
        mandateId: item.mandateId ? String(item.mandateId) : String(mandateData?.data?.[0]?.id),
        no_of_pr_raised: 0,
        no_of_pr_approved: 0,
        no_of_reworked_pr: 0,
      }))

      setFormValues(updatedFormValues)
    }
  }, [getSingleIdsrData, mandateData])

  useEffect(() => {
    if (getAllPlansList && getAllPlansList[0]?.id)
      getPlanForTheDay({ planforthedayId: getAllPlansList[0]?.id })
  }, [getAllPlansList])

  useEffect(() => {
    if (
      open &&
      getPlanForTheDayData &&
      getPlanForTheDayData?.length &&
      checkIdsrForm === 'create'
    ) {
      const updatedFormValues = getPlanForTheDayData.map((item: any) => ({
        projectName: AllProjectsName?.includes(item.projectName) ? item.projectName : 'Other',
        otherProjectName: item.otherProjectName || '',
        timeSpend: item.timeSpend || '',
        taskDescription: convertHTMLToPlainText(item.taskDescription) || '',
        priority: item.priority || 'High',
        statusId: '2',
        mandateId: item.mandateId ? String(item.mandateId) : String(mandateData?.data?.[0]?.id),
        no_of_pr_raised: 0,
        no_of_pr_approved: 0,
        no_of_reworked_pr: 0,
      }))
      setFormValues(updatedFormValues)
    }
  }, [getPlanForTheDayData, open])

  useEffect(() => {
    setTo(getManagerDetails?.email || '')
  }, [getManagerDetails])

  useEffect(() => {
    if (isUpdatedSingleIdsrList) {
      fetchAttendanceData({ userId: userId, tId: selectedMonthId })
      toast.success('Timesheet updated successfully')
      resetEditSingleIdsr()
    }
  }, [isUpdatedSingleIdsrList])

  const convertHTMLToPlainText = (html: string): string => {
    // Create a DOM parser to handle HTML strings
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    let result = ''

    // Traverse through the child nodes
    doc.body.childNodes.forEach((node) => {
      if (node.nodeName === 'P') {
        // If it's a paragraph, get its text content
        result += `${(node as HTMLElement).innerText}\n`
      } else if (node.nodeName === 'UL') {
        // If it's an unordered list, process the list items
        const listItems = node.childNodes
        listItems.forEach((li) => {
          if (li.nodeName === 'LI') {
            result += `- ${(li as HTMLElement).innerText}\n`
          }
        })
      }
    })

    return result.trim() // Remove any trailing newlines
  }

  const convertToUnorderedList = (content: string) => {
    // Split the input by newlines
    const lines = content.split('\n')

    let isInList = false
    let result = ''

    lines.forEach((line: string) => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('-')) {
        // If we're not already inside a list, start a new unordered list
        if (!isInList) {
          result += '<ul>'
          isInList = true
        }
        // Add list item by trimming the starting '-' and any extra whitespace
        result += `<li>${trimmedLine.substring(1).trim()}</li>`
      } else {
        // If we were inside a list and encounter a non-list item, close the unordered list
        if (isInList) {
          result += '</ul>'
          isInList = false
        }
        // Add the non-list text as a regular paragraph or line
        if (trimmedLine) {
          result += `<p>${trimmedLine}</p>`
        }
      }
    })

    // If the last line was a list, close the unordered list
    if (isInList) {
      result += '</ul>'
    }

    return result
  }

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        statusId: '2',
        priority: 'High',
        mandateId: String(mandateData?.data?.[0]?.id) ? String(mandateData?.data?.[0]?.id) : '',
        no_of_pr_raised: null,
        no_of_pr_approved: null,
        no_of_reworked_pr: null,
      },
    ])
  }

  const deleteFormFields = (indexToDelete: number) => {
    const newFormValues = [
      ...formValues.slice(0, indexToDelete),
      ...formValues.slice(indexToDelete + 1),
    ]
    setFormValues(newFormValues)
  }

  const handleProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        projectName: value,
        otherProjectName: value === 'Other' ? newData[index].otherProjectName : '',
      }
      return newData
    })
  }
  const handleOtherProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        otherProjectName: value,
      }
      return newData
    })
  }

  const handleTimeSpentChange = (index: number, value: string) => {
    const numericValue = Number(value)

    if (!isNaN(numericValue) && numericValue <= 240) {
      setFormValues((prevData) => {
        const newData = [...prevData]
        newData[index] = {
          ...newData[index],
          timeSpend: value,
        }
        return newData
      })
    }
  }

  const handlePRRaised = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        no_of_pr_raised: value === '' ? null : Number(value),
      }
      return newData
    })
  }

  const handlePRApproved = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        no_of_pr_approved: value === '' ? null : Number(value),
      }
      return newData
    })
  }

  const handleReworkedPR = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        no_of_reworked_pr: value === '' ? null : Number(value),
      }
      return newData
    })
  }

  const handleTaskDescriptionChange = (index: number, content: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        taskDescription: content,
      }
      return newData
    })
  }

  const handleCheckError = () => {
    const hasError =
      formValues.some((value) => {
        return (
          value.projectName === '' ||
          value.timeSpend === '' ||
          value.taskDescription === '' ||
          (value.projectName === 'Other' && value.otherProjectName === '') ||
          value.mandateId === '' ||
          value.statusId === '' ||
          value.priority === ''
        )
      }) ||
      toError ||
      !to ||
      (cc !== '' && ccError)
    return hasError
  }

  const handleCreateIDSR = () => {
    const updateText = formValues.map((value) => {
      return {
        ...value,
        taskDescription: convertToUnorderedList(value?.taskDescription),
      }
    })
    handleClose()
    if (handleCheckError()) {
      toast.error('Please fill in valid data in all the fields.')
    } else {
      // const data = {
      //   to: to,
      //   cc: cc,
      //   workWith: workWith?.userId ? workWith?.userId : 0,
      //   idsrData: updateText,
      //   is_draft: 0,
      // }
      const data = {
        to: to,
        cc: cc,
        workWith: 0,
        idsrData: updateText,
        is_draft: 0,
      }
      editSingleIdsr({ data, idsrId: singleIdsrId })
      resetForm()
    }
  }

  const resetForm = () => {
    setWorkWith('')
    setTo(getManagerDetails?.email || '')
    setToError(false)
    setCcError(false)
    setCc('')
    setFormValues([
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        mandateId: String(mandateData?.data?.[0]?.id),
        statusId: '2',
        priority: 'High',
        no_of_pr_raised: null,
        no_of_pr_approved: null,
        no_of_reworked_pr: null,
      },
    ])
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^$|^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  const handleProjectTypeChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        mandateId: String(value),
      }
      return newData
    })
  }

  const handleProjectStatusChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        statusId: String(value),
      }
      return newData
    })
  }

  const handlePriorityChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        priority: value,
      }
      return newData
    })
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  const handleSelectedEmployee = (event: any) => {
    const selectedEmployeeUserId = event.target.value
    setWorkWith(selectedEmployeeUserId)
  }

  return (
    <Dialog open={open} maxWidth='lg' className='create_IDSR_wrapper'>
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Typography variant='h5' sx={{ textAlign: 'center', marginTop: '-10px' }}>
          {checkIdsrForm === 'edit' ? 'Edit Timesheet' : 'Create Timesheet'}
        </Typography>
      </BootstrapDialogTitle>
      <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {/* <Grid item xs={3.9} sm={3.9} sx={{ marginBottom: '15px' }}>
            <InputField
              id='outlined-required'
              label='To'
              size='small'
              fullWidth
              value={to}
              onChange={(e) => handleTo(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={toError}
              helperText={toError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid
            item
            xs={8.1}
            sm={8.1}
            sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '-13px' }}
          >
            <Box sx={{ width: '51%' }}>
              <InputField1
                id='outlined-required'
                label='Cc'
                size='small'
                fullWidth
                value={cc}
                onChange={(e) => handleCc(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={ccError}
                helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
              />
            </Box>
            <Box sx={{ width: '49%', marginTop: '-5px' }}>
              <Autocomplete
                autoFocus={false}
                size='small'
                disablePortal
                id='select-working-employees'
                options={employeeRecords}
                getOptionLabel={(option: any) => option?.info || ''}
                value={workWith}
                sx={{
                  '.MuiInputBase-root': {
                    padding: '22px 11px',
                    borderRadius: '20px',
                    fontSize: '13px',
                    fontFamily: 'Montserrat-Medium',
                    height: '45px',
                  },
                  '& .MuiFormControl-root': {
                    margin: '0',
                    marginTop: '5px',
                  },
                  '& .MuiFormLabel-root ': {
                    backgroundColor: 'white',
                    width: '165px',
                  },
                  '.MuiFormLabel-asterisk': {
                    color: 'white',
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Working With' variant='outlined' />
                )}
                onChange={(event: any, newValue: any) => {
                  setWorkWith(newValue ?? '')
                }}
                ListboxProps={{
                  style: {
                    maxHeight: '200px',
                  },
                }}
              />
            </Box>
            <Tooltip title='Add Task' arrow>
              <AddCircleOutlineIcon
                onClick={addFormFields}
                fontSize='large'
                sx={{
                  cursor: 'pointer',
                  color: 'green',
                }}
              />
            </Tooltip>
          </Grid> */}

          <Grid item xs={6} sm={6} sx={{ marginBottom: '15px' }}>
            <InputField
              id='outlined-required'
              label='To'
              size='small'
              fullWidth
              value={to}
              onChange={(e) => handleTo(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={toError}
              helperText={toError ? 'Please enter email separated by commas' : ''}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '-13px' }}
          >
            <InputField1
              id='outlined-required'
              label='Cc'
              size='small'
              fullWidth
              value={cc}
              onChange={(e) => handleCc(e.target.value)}
              margin='normal'
              placeholder='Please enter email addresses separated by commas'
              error={ccError}
              helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
            />
            <Tooltip title='Add Task' arrow>
              <AddCircleOutlineIcon
                onClick={addFormFields}
                fontSize='large'
                sx={{
                  cursor: 'pointer',
                  color: 'green',
                }}
              />
            </Tooltip>
          </Grid>

          {formValues.map((element, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              className='project_wrapper'
              sx={{
                height: 'auto',
                overflow: 'hidden',
                display: 'flex', // Flex layout to align items in one line
                justifyContent: 'space-between',
                paddingTop: '0px !important',
                marginTop: '10px',
              }}
            >
              {/* First Grid: Holds all fields except Task Description (60%) */}
              <Grid
                item
                xs={12}
                sm={12}
                md={8} // 60% of the width
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  '& .MuiGrid-container': { paddingTop: '0px' },
                }}
              >
                <Grid item xs={element.projectName === 'Other' ? 2 : 2.4}>
                  <CustomFormControll>
                    <InputLabel
                      id='demo-simple-select-readonly-label'
                      sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                    >
                      Select Project <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelectField
                      labelId='select-project-label'
                      variant='outlined'
                      label='Select Project:'
                      type='small'
                      fullWidth
                      value={formValues[index]?.projectName || ''}
                      onChange={(e) => handleProjectNameChange(index, e.target.value)}
                    >
                      {AllProjectsName.map((project: string) => (
                        <MenuItem key={project} value={project}>
                          {project}
                        </MenuItem>
                      ))}
                      <MenuItem value='Other'>Other</MenuItem>
                    </StyledSelectField>
                  </CustomFormControll>
                </Grid>

                {element.projectName === 'Other' && (
                  <Grow in={element.projectName === 'Other'} timeout={500}>
                    <Grid item xs={2}>
                      <InputField
                        id='outlined-required'
                        label='Project Name'
                        size='small'
                        fullWidth
                        autoFocus
                        value={element.otherProjectName || ''}
                        onChange={(e) => handleOtherProjectNameChange(index, e.target.value)}
                        margin='normal'
                      />
                    </Grid>
                  </Grow>
                )}

                <Grid item xs={element.projectName === 'Other' ? 2 : 2.4}>
                  <CustomFormControll>
                    <InputLabel
                      id='demo-simple-select-readonly-label'
                      sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                    >
                      Mandate Type <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelectField
                      labelId='select-type-label'
                      variant='outlined'
                      label='Mandate Type:'
                      type='small'
                      fullWidth
                      value={formValues[index]?.mandateId}
                      onChange={(e) => handleProjectTypeChanges(index, e.target.value as string)}
                    >
                      {mandateData?.data?.map((type: any) => (
                        <MenuItem key={`${type.id}${type.mandate_name}`} value={String(type.id)}>
                          {type.mandate_name}
                        </MenuItem>
                      ))}
                    </StyledSelectField>
                  </CustomFormControll>
                </Grid>

                <Grid item xs={element.projectName === 'Other' ? 2 : 2.4}>
                  <CustomFormControll>
                    <InputLabel
                      id='demo-simple-select-readonly-label'
                      sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                    >
                      Task Status <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelectField
                      labelId='select-type-label'
                      variant='outlined'
                      label='Task Status:'
                      type='small'
                      fullWidth
                      value={String(formValues[index]?.statusId) || '2'}
                      onChange={(e) => handleProjectStatusChanges(index, e.target.value as string)}
                    >
                      {getTaskStatus?.map((type, index) => (
                        <MenuItem key={`${type.id}${type.status_name}`} value={String(type.id)}>
                          {type.status_name}
                        </MenuItem>
                      ))}
                    </StyledSelectField>
                  </CustomFormControll>
                </Grid>

                <Grid item xs={element.projectName === 'Other' ? 1.9 : 2.4}>
                  <CustomFormControll>
                    <InputLabel
                      id='demo-simple-select-readonly-label'
                      sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                    >
                      Priority <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelectField
                      labelId='select-type-label'
                      variant='outlined'
                      label='Priority:'
                      type='small'
                      fullWidth
                      value={formValues[index]?.priority}
                      onChange={(e) => handlePriorityChanges(index, e.target.value as string)}
                    >
                      {priority?.map((type: any, index: number) => (
                        <MenuItem key={`${type}`} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </StyledSelectField>
                  </CustomFormControll>
                </Grid>

                <Grid item xs={element.projectName === 'Other' ? 2.1 : 2.4}>
                  <InputField
                    id='outlined-required'
                    label='Time Spent(Min)'
                    size='small'
                    fullWidth
                    value={formValues[index]?.timeSpend || ''}
                    onChange={(e) => handleTimeSpentChange(index, e.target.value)}
                    margin='normal'
                    placeholder='Max. 240 minutes'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span style={{ fontSize: '14px' }}>
                            {formValues[index]?.timeSpend
                              ? convertMinutesToHours(Number(formValues[index].timeSpend))
                              : ''}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {/* Second Grid: Holds Task Description (40%) */}
              <Grid
                item
                xs={12}
                sm={12}
                md={4} // 40% of the width
                sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                <TextField
                  label='Task Description'
                  multiline
                  fullWidth
                  variant='outlined'
                  value={formValues[index]?.taskDescription || ''}
                  onChange={(e) => handleTaskDescriptionChange(index, e.target.value)}
                  sx={{
                    marginTop: '20px',
                    '& .MuiInputBase-inputMultiline': {
                      position: 'relative',
                      left: '12px',
                      top: '6px',
                    },
                    '& .MuiOutlinedInput-root': {
                      width: index === 0 ? '89%' : '101%',
                      fontSize: '12px',
                      fontFamily: 'Montserrat-Medium',
                      borderRadius: '30px',
                      minHeight: '30px',
                      padding: '6px 8px',
                      alignItems: 'center',
                    },
                    '& .MuiOutlinedInput-input': {
                      fontSize: '12px',
                      fontFamily: 'Montserrat-Medium',
                      minHeight: '30px',
                      padding: '0',
                      boxSizing: 'border-box',
                      lineHeight: '1.2',
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      marginTop: '-4px',
                      fontSize: '13px !important',
                      fontFamily: 'Montserrat-Medium !important',
                      '& .MuiFormLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    '& textarea': {
                      fontSize: '12px',
                      fontFamily: 'Montserrat-Medium',
                      height: 'auto',
                      width: '95%',
                      minHeight: '35px',
                      overflowY: 'auto',
                    },
                  }}
                />

                {index !== 0 && (
                  <Tooltip title='Remove Task' arrow>
                    <HighlightOffIcon
                      onClick={() => deleteFormFields(index)}
                      fontSize='large'
                      sx={{
                        cursor: 'pointer',
                        color: 'red',
                        marginRight: '-3px',
                      }}
                    />
                  </Tooltip>
                )}
              </Grid>
              {/* <Grid item xs={12} marginTop={'-3px'}>
                <Divider />
              </Grid> */}
            </Grid>
          ))}
        </Grid>
      </Box>

      <DialogActions className='button_wrapper'>
        <CancelButton onClick={handleClose}>CANCEL</CancelButton>
        <Button
          sx={{
            borderRadius: '20px',
            fontSize: '13px',
            height: '42px',
            fontFamily: 'Montserrat-SemiBold',
            width: '100px',

            '&.Mui-disabled': {
              opacity: 0.5,
              color: '#ffffff',
              cursor: 'not-allowed',
            },
          }}
          onClick={handleCreateIDSR}
          disabled={handleCheckError()}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CreatePlanDialog = ({
  employeeRecords,
  open,
  onClose,
  createPlanForTheDay,
  AllProjectsName,
  getStatusType,
  getManagerDetails,
  mandateData,
  getPlanForTheDayData,
  getPfpId,
  setPfpId,
  isGetPlanForTheDayList,
  isUpdatedPlanForTheDayList,
  getPlanForTheDay,
  resetGetPlanForTheDay,
  resetEditPlanForTheDay,
  editPlanForTheDay,
  checkPfpForm,
  setCheckPfpForm,
  fetchAttendanceTimesheet,
  getUserById,
  isGetPlanForTheDayLoader,
  getCcData,

  recentIDSR,
  isIdsrRecentData,
  getIdsrRecentData,
}: {
  isIdsrRecentData: any
  getIdsrRecentData: any
  recentIDSR: any
  employeeRecords: any
  mandateData: any
  open: boolean
  onClose: () => void
  createPlanForTheDay: (data: any) => void
  AllProjectsName: string[]
  getStatusType: [{ id: number; type_name: string }]
  getManagerDetails: { email: string; name: string }
  getPlanForTheDayData: any
  getPfpId: any
  setPfpId: any
  isGetPlanForTheDayList: any
  getPlanForTheDay: any
  resetGetPlanForTheDay: any
  resetEditPlanForTheDay: any
  editPlanForTheDay: any
  checkPfpForm: any
  setCheckPfpForm: any
  isUpdatedPlanForTheDayList: any
  fetchAttendanceTimesheet: any
  getUserById: any
  isGetPlanForTheDayLoader: any
  getCcData: any
}) => {
  const [to, setTo] = useState(getManagerDetails?.email || '')
  const [toError, setToError] = useState(false)
  const [ccError, setCcError] = useState(false)
  const [cc, setCc] = useState(getCcData ? getCcData : '')
  const [workWith, setWorkWith] = useState<any>('')
  const [formValues, setFormValues] = useState<IFormValues[]>([
    {
      projectName: '',
      otherProjectName: '',
      timeSpend: '',
      taskDescription: '',
      priority: 'High',
      mandateId: String(mandateData?.data?.[0]?.id),
    },
  ])

  useEffect(() => {
    if (checkPfpForm === 'create') recentIDSR()
  }, [open])

  useEffect(() => {
    if (getCcData) {
      setCc(getCcData)
    }
  }, [getCcData, open])

  useEffect(() => {
    if (
      getIdsrRecentData?.data?.length &&
      getIdsrRecentData?.data.length > 0 &&
      checkPfpForm === 'create'
    ) {
      setWorkWith(
        employeeRecords?.find((value: any) => value?.userId === getIdsrRecentData?.workWith) ?? '',
      )
      const updatedFormValues = getIdsrRecentData?.data.map((item: any) => ({
        projectName: AllProjectsName?.includes(item.projectName) ? item.projectName : 'Other',
        otherProjectName: item.otherProjectName || '',
        timeSpend: item.timeSpend || '',
        taskDescription: convertHTMLToPlainText(item.taskDescription) || '',
        priority: item.priority || 'High',
        mandateId: item.mandateId ? String(item.mandateId) : String(mandateData?.data?.[0]?.id),
      }))

      setFormValues(updatedFormValues)
    }
  }, [getIdsrRecentData, mandateData])

  useEffect(() => {
    if (getPlanForTheDayData && getPlanForTheDayData.length > 0 && checkPfpForm === 'edit') {
      setWorkWith(
        employeeRecords?.find((value: any) => value?.userId === getIdsrRecentData?.workWith) ?? '',
      )
      const updatedFormValues = getPlanForTheDayData.map((item: any) => ({
        projectName: AllProjectsName?.includes(item.projectName) ? item.projectName : 'Other',
        otherProjectName: item.otherProjectName || '',
        timeSpend: item.timeSpend || '',
        taskDescription: convertHTMLToPlainText(item.taskDescription) || '',
        priority: item.priority || 'High',
        mandateId: item.mandateId ? String(item.mandateId) : String(mandateData?.data?.[0]?.id),
      }))

      setFormValues(updatedFormValues)
    }
  }, [getPlanForTheDayData, mandateData])

  useEffect(() => {
    setTo(getManagerDetails?.email || '')
    setFormValues((prevFormValues) => {
      return prevFormValues.map((formValue: any) => ({
        ...formValue,
        mandateId: String(mandateData?.data?.[0]?.id),
      }))
    })
  }, [getManagerDetails, mandateData])

  const addFormFields = () => {
    setWorkWith('')
    setFormValues([
      ...formValues,
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        priority: 'High',
        mandateId: String(mandateData?.data?.[0]?.id),
      },
    ])
  }

  const handleSelectedEmployee = (event: any) => {
    const selectedEmployeeUserId = event.target.value
    setWorkWith(selectedEmployeeUserId)
  }

  const convertHTMLToPlainText = (html: string): string => {
    // Create a DOM parser to handle HTML strings
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    let result = ''

    // Traverse through the child nodes
    doc.body.childNodes.forEach((node) => {
      if (node.nodeName === 'P') {
        // If it's a paragraph, get its text content
        result += `${(node as HTMLElement).innerText}\n`
      } else if (node.nodeName === 'UL') {
        // If it's an unordered list, process the list items
        const listItems = node.childNodes
        listItems.forEach((li) => {
          if (li.nodeName === 'LI') {
            result += `- ${(li as HTMLElement).innerText}\n`
          }
        })
      }
    })

    return result.trim() // Remove any trailing newlines
  }

  const convertToUnorderedList = (content: string) => {
    // Split the input by newlines
    const lines = content.split('\n')

    let isInList = false
    let result = ''

    lines.forEach((line: string) => {
      const trimmedLine = line.trim()

      if (trimmedLine.startsWith('-')) {
        // If we're not already inside a list, start a new unordered list
        if (!isInList) {
          result += '<ul>'
          isInList = true
        }
        // Add list item by trimming the starting '-' and any extra whitespace
        result += `<li>${trimmedLine.substring(1).trim()}</li>`
      } else {
        // If we were inside a list and encounter a non-list item, close the unordered list
        if (isInList) {
          result += '</ul>'
          isInList = false
        }
        // Add the non-list text as a regular paragraph or line
        if (trimmedLine) {
          result += `<p>${trimmedLine}</p>`
        }
      }
    })

    // If the last line was a list, close the unordered list
    if (isInList) {
      result += '</ul>'
    }

    return result
  }
  const deleteFormFields = (indexToDelete: number) => {
    const newFormValues = [
      ...formValues.slice(0, indexToDelete),
      ...formValues.slice(indexToDelete + 1),
    ]
    setFormValues(newFormValues)
  }

  const handleProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        projectName: value,
        otherProjectName: value === 'Other' ? newData[index].otherProjectName : '',
      }
      return newData
    })
  }

  const handleProjectTypeChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        mandateId: String(value),
      }
      return newData
    })
  }

  const handleOtherProjectNameChange = (index: number, value: any) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        otherProjectName: value,
      }
      return newData
    })
  }

  const handleTimeSpentChange = (index: number, value: string) => {
    const numericValue = Number(value)

    if (!isNaN(numericValue) && numericValue <= 240) {
      setFormValues((prevData) => {
        const newData = [...prevData]
        newData[index] = {
          ...newData[index],
          timeSpend: value,
        }
        return newData
      })
    }
  }
  const handleTaskDescriptionChange = (index: number, content: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        taskDescription: content,
      }
      return newData
    })
  }

  const handleCheckError = () => {
    const hasError =
      formValues.some((value) => {
        return (
          value.projectName === '' ||
          value.timeSpend === '' ||
          value.taskDescription === '' ||
          (value.projectName === 'Other' && value.otherProjectName === '') ||
          value.mandateId === '' ||
          value.priority === ''
        )
      }) ||
      toError ||
      !to ||
      (cc !== '' && ccError)
    return hasError
  }

  const handleCreatePlanForTheDay = () => {
    const updateText = formValues.map((value) => {
      return {
        ...value,
        taskDescription: convertToUnorderedList(value?.taskDescription),
      }
    })
    handleClose()
    if (handleCheckError()) {
      toast.error('Please fill in valid data in all the fields.')
    } else {
      // const data = {
      //   to: to,
      //   cc: cc,
      //   workWith: workWith?.userId ? workWith?.userId : 0,
      //   planfordayData: updateText,
      // }
      const data = {
        to: to,
        cc: cc,
        workWith: 0,
        planfordayData: updateText,
      }
      checkPfpForm === 'edit'
        ? editPlanForTheDay({ data, planforthedayId: getPfpId })
        : createPlanForTheDay(data)
      resetGetPlanForTheDay()
      resetForm()
    }
  }

  const resetForm = () => {
    setTo(getManagerDetails?.email || '')
    setToError(false)
    setCcError(false)
    setCc('')
    setFormValues([
      {
        projectName: '',
        otherProjectName: '',
        timeSpend: '',
        taskDescription: '',
        priority: 'High',
        mandateId: String(mandateData?.data?.[0]?.id) ?? '',
      },
    ])
  }

  const handleClose = () => {
    onClose()
    resetForm()
  }

  const emailListRegex = /^$|^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

  const handleTo = (value: string) => {
    setToError(!emailListRegex.test(value))
    setTo(value)
  }

  const handleCc = (value: string) => {
    setCcError(!emailListRegex.test(value))
    setCc(value)
  }

  const handlePriorityChanges = (index: number, value: string) => {
    setFormValues((prevData) => {
      const newData = [...prevData]
      newData[index] = {
        ...newData[index],
        priority: value,
      }
      return newData
    })
  }

  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  return (
    <>
      <Dialog open={isGetPlanForTheDayLoader} PaperProps={{ style: loaderProps }}>
        <CircularProgress color='secondary' />
      </Dialog>
      <Dialog open={open} maxWidth='lg' className='create_IDSR_wrapper'>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Typography variant='h5' sx={{ textAlign: 'center', marginTop: '-10px' }}>
            {`${checkPfpForm === 'edit' ? 'Edit Plan For The Day' : 'Create Plan For The Day'}`}
          </Typography>
        </BootstrapDialogTitle>
        <Box sx={{ padding: '0 16px', marginTop: '-8px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            {/* <Grid item xs={3.7} sm={3.7} sx={{ marginBottom: '15px' }}>
              <InputField
                id='outlined-required'
                label='To'
                size='small'
                fullWidth
                value={to}
                onChange={(e) => handleTo(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={toError}
                helperText={toError ? 'Please enter email separated by commas' : ''}
              />
            </Grid>
            <Grid
              item
              xs={8.3}
              sm={8.3}
              sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '-13px', paddingLeft: '5px' }}
            >
              <Box sx={{ width: '50%' }}>
                <InputField1
                  id='outlined-required'
                  label='Cc'
                  size='small'
                  fullWidth
                  value={cc}
                  onChange={(e) => handleCc(e.target.value)}
                  margin='normal'
                  placeholder='Please enter email addresses separated by commas'
                  error={ccError}
                  helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
                />
              </Box>
              <Box sx={{ width: '49%', marginTop: '-5px' }}>
                <Autocomplete
                  autoFocus={false}
                  size='small'
                  disablePortal
                  id='select-working-employees'
                  options={employeeRecords}
                  getOptionLabel={(option: any) => option?.info || ''}
                  value={workWith}
                  sx={{
                    '.MuiInputBase-root': {
                      padding: '22px 11px',
                      borderRadius: '20px',
                      fontSize: '13px',
                      fontFamily: 'Montserrat-Medium',
                      height: '42px',
                    },
                    '& .MuiFormControl-root': {
                      margin: '0',
                      marginTop: '5px',
                    },
                    '& .MuiFormLabel-root ': {
                      backgroundColor: 'white',
                      width: '165px',
                    },
                    '.MuiFormLabel-asterisk': {
                      color: 'white',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Working With' variant='outlined' />
                  )}
                  onChange={(event: any, newValue: any) => {
                    setWorkWith(newValue ?? '')
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: '200px',
                    },
                  }}
                />
              </Box>

              <Tooltip title='Add Task' arrow>
                <AddCircleOutlineIcon
                  onClick={addFormFields}
                  fontSize='large'
                  sx={{
                    cursor: 'pointer',
                    color: 'green',
                  }}
                />
              </Tooltip>
            </Grid> */}

            <Grid item xs={6} sm={6} sx={{ marginBottom: '15px' }}>
              <InputField
                id='outlined-required'
                label='To'
                size='small'
                fullWidth
                value={to}
                onChange={(e) => handleTo(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={toError}
                helperText={toError ? 'Please enter email separated by commas' : ''}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '-13px' }}
            >
              <InputField1
                id='outlined-required'
                label='Cc'
                size='small'
                fullWidth
                value={cc}
                onChange={(e) => handleCc(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={ccError}
                helperText={cc && ccError ? 'Please enter email separated by commas' : ''}
              />
              <Tooltip title='Add Task' arrow>
                <AddCircleOutlineIcon
                  onClick={addFormFields}
                  fontSize='large'
                  sx={{
                    cursor: 'pointer',
                    color: 'green',
                  }}
                />
              </Tooltip>
            </Grid>

            {formValues.map((element, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                key={index}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                className='project_wrapper'
                sx={{
                  height: 'auto',
                  overflow: 'hidden',
                  display: 'flex', // Flex layout to align items in one line
                  justifyContent: 'space-between',
                  paddingTop: '0px !important',
                  marginTop: '10px',
                }}
              >
                {/* Left section with select fields (60% width) */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={element.projectName === 'Other' ? 6.8 : 6} // 60% width (out of 12 columns)
                  container
                  spacing={2} // Add spacing between items
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    '& .MuiGrid-container': { paddingTop: '0px' },
                  }}
                >
                  <Grid item xs={element.projectName === 'Other' ? 2.4 : 3.4}>
                    <CustomFormControll>
                      <InputLabel
                        id='demo-simple-select-readonly-label'
                        sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                      >
                        Select Project <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-project-label'
                        variant='outlined'
                        label='Select Project:'
                        type='small'
                        fullWidth
                        value={formValues[index]?.projectName || ''}
                        onChange={(e) => handleProjectNameChange(index, e.target.value)}
                      >
                        {AllProjectsName.map((project: string, index: number) => (
                          <MenuItem key={project} value={project}>
                            {project}
                          </MenuItem>
                        ))}
                        <MenuItem value='Other'>Other</MenuItem>
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  {element.projectName === 'Other' && (
                    <Grow in={element.projectName === 'Other'} timeout={500}>
                      <Grid item xs={2.4}>
                        <InputField
                          id='outlined-required'
                          label='Project Name'
                          size='small'
                          fullWidth
                          autoFocus
                          value={element.otherProjectName || ''}
                          onChange={(e) => handleOtherProjectNameChange(index, e.target.value)}
                          margin='normal'
                        />
                      </Grid>
                    </Grow>
                  )}

                  <Grid item xs={element.projectName === 'Other' ? 2.6 : 3}>
                    <CustomFormControll>
                      <InputLabel
                        id='demo-simple-select-readonly-label'
                        sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                      >
                        Mandate Type <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-type-label'
                        variant='outlined'
                        label='Mandate Type:'
                        type='small'
                        fullWidth
                        value={formValues[index]?.mandateId}
                        onChange={(e) => handleProjectTypeChanges(index, e.target.value as string)}
                      >
                        {mandateData?.data?.map((type: any) => (
                          <MenuItem key={`${type.id}${type.id}`} value={String(type.id)}>
                            {type.mandate_name}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  <Grid item xs={element.projectName === 'Other' ? 2 : 2.6}>
                    <CustomFormControll>
                      <InputLabel
                        id='demo-simple-select-readonly-label'
                        sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                      >
                        Priority <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <StyledSelectField
                        labelId='select-type-label'
                        variant='outlined'
                        label='Priority:'
                        type='small'
                        fullWidth
                        value={formValues[index]?.priority}
                        onChange={(e) => handlePriorityChanges(index, e.target.value as string)}
                      >
                        {priority?.map((type: any, index: number) => (
                          <MenuItem key={`${type}`} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                    </CustomFormControll>
                  </Grid>

                  <Grid item xs={element.projectName === 'Other' ? 2.6 : 2.9}>
                    <InputField
                      id='outlined-required'
                      label='Est Time (Mins)'
                      size='small'
                      fullWidth
                      value={formValues[index]?.timeSpend || ''}
                      onChange={(e) => handleTimeSpentChange(index, e.target.value)}
                      margin='normal'
                      placeholder='Max. 240 minutes'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <span style={{ fontSize: '14px' }}>
                              {formValues[index]?.timeSpend
                                ? convertMinutesToHours(Number(formValues[index].timeSpend))
                                : ''}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                {/* Right section with MUI TextField (40% width) */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={element.projectName === 'Other' ? 5.2 : 6}
                  sx={{ display: 'flex', gap: '14px', alignItems: 'center' }}
                >
                  <TextField
                    id='editor'
                    label='Task Description'
                    multiline
                    fullWidth
                    variant='outlined'
                    value={formValues[index]?.taskDescription || ''}
                    onChange={(e) => handleTaskDescriptionChange(index, e.target.value)}
                    sx={{
                      marginTop: '20px',
                      '& .MuiInputBase-inputMultiline': {
                        position: 'relative',
                        left: '12px',
                        top: '6px !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        width: index === 0 ? '91%' : '101%',
                        fontSize: '12px',
                        fontFamily: 'Montserrat-Medium',
                        borderRadius: '30px',
                        minHeight: '30px', // Set the minimum height to 35px
                        padding: '6px 8px', // Reduce the padding to make the field smaller
                        alignItems: 'center', // Ensure vertical alignment of text
                      },
                      '& .MuiOutlinedInput-input': {
                        fontSize: '12px',
                        fontFamily: 'Montserrat-Medium',
                        minHeight: '30px', // Set minimum height to 35px
                        padding: '0', // Adjust padding to minimize height
                        boxSizing: 'border-box', // Ensure padding and borders are calculated correctly
                        lineHeight: '1.2', // Adjust the line height to control the text appearance
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        marginTop: '-4px',
                        fontSize: '13px !important',
                        fontFamily: 'Montserrat-Medium !important',
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      },
                      '& textarea': {
                        fontSize: '12px',
                        fontFamily: 'Montserrat-Medium',
                        width: '95%',
                        height: 'auto', // Set the height to auto, so it grows with content
                        minHeight: '35px', // Ensure a minimum height of 35px
                        overflowY: 'auto', // Enable scrolling for content that exceeds height
                      },
                    }}
                  />

                  {index !== 0 && (
                    <Tooltip title='Remove Task' arrow>
                      <HighlightOffIcon
                        onClick={() => deleteFormFields(index)}
                        fontSize='large'
                        sx={{
                          // marginRight: '-1px',
                          cursor: 'pointer',
                          color: 'red', // Set the color here
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <DialogActions className='button_wrapper'>
          <CancelButton onClick={handleClose}>CANCEL</CancelButton>
          <Button
            sx={{
              borderRadius: '20px',
              fontSize: '13px',
              height: '42px',
              fontFamily: 'Montserrat-SemiBold',
              width: '100px',

              '&.Mui-disabled': {
                opacity: 0.5,
                color: '#ffffff',
                cursor: 'not-allowed',
              },
            }}
            onClick={handleCreatePlanForTheDay}
            disabled={handleCheckError()}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const IDSR = (props: any) => {
  const {
    createPlanForTheDay,
    createIDSR,
    isCreatingIDSR,
    isCreatingPlanForTheDay,
    isIDSRCreated,
    getAllIDSRsList,
    getAllPlansList,
    resetIDSR,
    fetchAttendanceTimesheet,
    fetchPlansForTheDay,
    getUserById,
    AttendanceTimesheet,
    fetchAttendanceData,
    isFetchingIDSRs,
    isFetchingPlans,
    AllProjectsName,
    fetchProjectsName,
    fetchStatusType,
    fetchTaskStatus,
    getStatusType,
    getTaskStatus,
    isPlanForTheDayCreated,
    resetPlanForTheDay,
    getUserDetails,
    mandateTypeData,
    getMandateType,
    getPlanForTheDay,
    getPlanForTheDayData,
    isGetPlanForTheDayList,
    isUpdatedPlanForTheDayList,
    resetGetPlanForTheDay,
    resetEditPlanForTheDay,
    editPlanForTheDay,
    isUpdateLoaderPlanForTheDayList,
    isGetPlanForTheDayLoader,

    getSingleIdsr,
    editSingleIdsr,
    resetEditSingleIdsr,
    resetGetSingleIdsr,
    getSingleIdsrData,
    isGetSingleIdsrList,
    isUpdatedSingleIdsrList,
    isUpdateLoaderSingleIdsrList,

    getAllRCAs,
    getAllRCAsList,
    getUserDetailsRCA,
    createRCA,
    isCreatingRCA,
    isRCACreated,
    resetRCA,
    AllProjectsNameForRCA,
    employeeRecords,

    recentIDSR,
    isIdsrRecentData,
    getIdsrRecentData,
  } = props

  const [selectedIDSR, setSelectedIDSR] = useState<any | null>(null)
  const [selectedPlanForTheDay, setSelectedPlanForTheDay] = useState<any | null>(null)
  const [isNewIDSRDialogOpen, setisNewIDSRDialogOpen] = useState(false)
  const [isNewPlanDialogOpen, setNewPlanDialog] = useState(false)
  const [selectedTimeSheetMonth, setSelectedTimeSheetMonth] = useState('')
  const [selectedPlanForTheDayMonth, setSelectedPlanForTheDayMonth] = useState('')
  const [checkOpen, setCheckOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false)
  const [hoverTooltipOpen, setHoverTooltipOpen] = useState(false)
  const [getPfpId, setPfpId] = useState()
  const [checkPfpForm, setCheckPfpForm] = useState('create')
  const [checkIdsrForm, setCheckIdsrForm] = useState('create')
  const [singleIdsrId, setSingleIdsrId] = useState()
  const [latestPlanForTheDayId, setLatestPlanForTheDayId] = useState(getAllPlansList[0]?.id ?? '')
  const [getCcData, setCcData] = useState('')

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const handleRowClick = (data: any) => {
    setSelectedIDSR(data)
  }

  const handlePlanRowClick = (data: any) => {
    setSelectedPlanForTheDay(data)
  }

  const handleCloseModal = () => {
    setSelectedIDSR(null)
  }

  const handleClosePlanModal = () => {
    setSelectedPlanForTheDay(null)
  }

  const handleOpenNewIDSRDialog = () => {
    setCheckIdsrForm('create')
    setisNewIDSRDialogOpen(true)
  }

  const handleNewPlanDialog = () => {
    setCheckPfpForm('create')
    setNewPlanDialog(true)
  }

  const handleCloseNewIDSRialog = () => {
    setisNewIDSRDialogOpen(false)
  }

  const handleCloseNewPlanDialog = () => {
    setNewPlanDialog(false)
  }

  useEffect(() => {
    getMandateType({
      data: { pageSize: '', search: '', page: '' },
    })
  }, [])

  useEffect(() => {
    if (getUserById?.id) {
      fetchAttendanceTimesheet(getUserById.id)
      fetchProjectsName(getUserById?.id)
      fetchStatusType()
      fetchTaskStatus()
    }
  }, [getUserById])

  useEffect(() => {
    if (AttendanceTimesheet?.length && getUserById?.id) {
      switch (selectedTab) {
        case 0:
          setSelectedPlanForTheDayMonth(
            selectedPlanForTheDayMonth !== ''
              ? selectedPlanForTheDayMonth
              : AttendanceTimesheet[0].id,
          )
          setSelectedTimeSheetMonth('')
          fetchPlansForTheDay({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
          break
        case 1:
          setSelectedTimeSheetMonth(
            selectedTimeSheetMonth !== '' ? selectedTimeSheetMonth : AttendanceTimesheet[0].id,
          )
          setSelectedPlanForTheDayMonth('')
          fetchAttendanceData({ userId: getUserById.id, tId: AttendanceTimesheet[0].id })
          break
      }
    }
  }, [AttendanceTimesheet, selectedTab])

  useEffect(() => {
    if (isIDSRCreated) {
      toast.success('Timesheet sent successfully')
      fetchAttendanceTimesheet(getUserById.id)
      resetIDSR()
    }
  }, [isIDSRCreated])

  useEffect(() => {
    if (isPlanForTheDayCreated) {
      toast.success('Plan for the day sent successfully')
      fetchAttendanceTimesheet(getUserById.id)
      resetPlanForTheDay()
    }
  }, [isPlanForTheDayCreated])

  useEffect(() => {
    if (isUpdatedPlanForTheDayList) {
      setCheckPfpForm('create')
      fetchAttendanceTimesheet(getUserById.id)
      toast.success('Plan for the day updated successfully')
      resetEditPlanForTheDay()
    }
  }, [isUpdatedPlanForTheDayList])

  const CreateRcaDialog = ({
    open,
    onClose,
    createRCA,
    AllProjectsName,
  }: {
    open: boolean
    onClose: () => void
    createRCA: (data: any) => void
    AllProjectsName: string[]
  }) => {
    const [to, setTo] = useState('')
    const [toError, setToError] = useState(false)
    const [ccError, setCcError] = useState(false)
    const [cc, setCc] = useState('')
    const [projectName, setProjectName] = useState('')
    const [body, setBody] = useState(() => {
      return ReactDOMServer.renderToString(<RcaFormat />)
    })
    const [otherProjectName, setOtherProjectName] = useState('')

    const handleCreateRca = () => {
      handleClose()
      let data = {
        to_email: to,
        cc_email: cc,
        body: body,
        project_name: projectName,
        other_project_name: otherProjectName,
      }
      createRCA(data)
    }

    const resetForm = () => {
      setTo('')
      setToError(false)
      setCcError(false)
      setCc('')
      setProjectName('')
      setOtherProjectName('')
      setBody(() => {
        return ReactDOMServer.renderToString(<RcaFormat />)
      })
    }

    const handleClose = () => {
      onClose()
      resetForm()
    }

    const emailListRegex = /^[\w\.-]+@[\w\.-]+(?:,\s*[\w\.-]+@[\w\.-]+)*$/

    const handleTo = (value: string) => {
      setToError(!emailListRegex.test(value))
      setTo(value)
    }

    const handleCc = (value: string) => {
      setCcError(!emailListRegex.test(value))
      setCc(value)
    }

    const handleProjectChange = (event: any) => {
      setProjectName(event.target.value)
    }

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (projectName === 'Other' && inputRef.current) {
        inputRef.current.focus()
      }
    }, [projectName])

    return (
      <Dialog open={open} maxWidth='lg' fullWidth>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Typography variant='h5' sx={{ textAlign: 'center' }}>
            Create RCA
          </Typography>
        </BootstrapDialogTitle>
        <Box sx={{ padding: '0 16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            sx={{ flexGrow: 1 }}
          >
            <Grid item xs={6} sm={6}>
              <InputField
                id='outlined-required'
                label='To'
                size='small'
                fullWidth
                value={to}
                onChange={(e) => handleTo(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={toError}
                helperText={toError ? 'Please enter email separated by commas' : ''}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                id='outlined-required'
                label='Cc'
                size='small'
                fullWidth
                value={cc}
                onChange={(e) => handleCc(e.target.value)}
                margin='normal'
                placeholder='Please enter email addresses separated by commas'
                error={ccError}
                helperText={ccError ? 'Please enter email separated by commas' : ''}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <StyledFormControl>
                <InputLabel
                  id='demo-simple-select-readonly-label'
                  sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                >
                  Select Project <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <SelectField
                  variant='outlined'
                  label='Select Project:'
                  type='small'
                  fullWidth
                  value={projectName}
                  onChange={handleProjectChange}
                >
                  {AllProjectsName.map((project: string) => (
                    <MenuItem key={project} value={project}>
                      {project}
                    </MenuItem>
                  ))}
                  <MenuItem value='Other'>Other</MenuItem>
                </SelectField>
              </StyledFormControl>
            </Grid>
            {projectName === 'Other' && (
              <Grid item xs={6} sm={6} sx={{ marginTop: '-3px' }}>
                <InputField
                  id='outlined-required'
                  label='Project Name'
                  size='small'
                  fullWidth
                  autoFocus
                  value={otherProjectName}
                  onChange={(e) => setOtherProjectName(e.target.value)}
                  margin='normal'
                  inputRef={inputRef}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} sx={{ flexGrow: 1 }}>
              <CKEditor
                editor={ClassicEditor}
                data={body}
                onReady={() => {}}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setBody(data)
                }}
              />
            </Grid>
          </Grid>
          <DialogActions sx={{ padding: '16px', justifyContent: 'flex-end', marginTop: 'auto' }}>
            <CancelButton onClick={handleClose}>CANCEL</CancelButton>
            <Button
              sx={{
                borderRadius: '20px',
                fontSize: '13px',
                height: '42px',
                fontFamily: 'Montserrat-SemiBold',
                width: '100px',
                '&.Mui-disabled': {
                  opacity: 0.5,
                  color: '#ffffff',
                  cursor: 'not-allowed',
                },
              }}
              onClick={handleCreateRca}
              disabled={
                !to.length ||
                !cc.length ||
                !body.length ||
                !projectName.length ||
                (projectName === 'Other' && !otherProjectName.length)
              }
            >
              SUBMIT
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  const handleSelectTimeSheetMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedTimeSheetMonth(selectedMonthId)
      fetchAttendanceData({
        userId: getUserById?.id,
        tId: selectedMonthId,
      })
    },
    [getUserById?.id, fetchAttendanceData],
  )

  const handleSelectPlanForTheDayMonth = useCallback(
    (event: any) => {
      const selectedMonthId = event.target.value
      setSelectedPlanForTheDayMonth(selectedMonthId)
      fetchPlansForTheDay({ userId: getUserById?.id, tId: selectedMonthId })
    },
    [getUserById?.id, fetchPlansForTheDay],
  )

  const fetchAttendanceData1 = (data: any) => {
    fetchAttendanceData({ userId: getUserById.id, tId: data.tId })
  }

  function convertTextToHTML(text: string) {
    let htmlText = text.replace(/\\n/g, '<br>')

    htmlText = htmlText.replace(/\*([^*]+)\*/g, '<strong>$1</strong>')

    return <div dangerouslySetInnerHTML={{ __html: htmlText }} />
  }

  const handleCopyContent = () => {
    const stripHTML = (html: any) => {
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*')
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '')

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n')
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n')
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }

    const subject = selectedPlanForTheDay?.subject || ''
    const body = stripHTML(selectedPlanForTheDay?.body || '')
    const content = `${subject}\n${body}`

    navigator.clipboard.writeText(content).then(() => {
      setCopyTooltipOpen(true)
      setTimeout(() => {
        setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  const handleCopyTimesheetContent = () => {
    const stripHTML = (html: any) => {
      let temp = html.replace(/<p>/g, '\n')
      temp = temp.replace(/<\/p>/g, '\n')
      temp = temp.replace(/<br\s*\/?>/gi, '\n')
      temp = temp.replace(/<strong>/g, '*')
      temp = temp.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      temp = temp.replace(/<\/strong>/g, '*')
      temp = temp.replace(/<em>/g, '*')
      temp = temp.replace(/<\/em>/g, '*')
      temp = temp.replace(/<i>/g, '*').replace(/<\/i>/g, '*')
      temp = temp.replace(/<li>/g, '\n ').replace(/<\/li>/g, '')

      temp = temp.replace(/<ol>/g, '\n').replace(/<\/ol>/g, '\n')
      temp = temp.replace(/<ul>/g, '\n').replace(/<\/ul>/g, '\n')
      temp = temp.replace(/<u>/g, '__')
      temp = temp.replace(/<\/u>/g, '__')
      temp = temp.replace(/&nbsp;/g, ' ')
      temp = temp.replace(/<[^>]+>/g, '')
      temp = temp.replace(/&amp;/g, '&')
      temp = temp.replace(/&\$/g, '$')

      return temp
    }
    const subject = selectedIDSR?.subject || ''
    const body = stripHTML(selectedIDSR?.body)
    const projectDetails = `
      Project Name: ${
        selectedIDSR?.project_name === 'Other'
          ? selectedIDSR?.other_project_name
          : selectedIDSR?.project_name
      }
      Time Spent: ${selectedIDSR?.time_spent} mins
      No. of PR Raised: ${selectedIDSR?.no_of_pr_raised ?? 0}
      No. of PR Approved: ${selectedIDSR?.no_of_pr_approved ?? 0}
      No. of Reworked PR: ${selectedIDSR?.no_of_reworked_pr ?? 0}
    `
    const content = `${subject}\n${projectDetails}\n${body}`

    navigator.clipboard.writeText(content).then(() => {
      setCopyTooltipOpen(true)
      setTimeout(() => {
        setCopyTooltipOpen(false)
      }, 2000)
    })
  }

  const navigate = useNavigate()

  const [isNewRCADialogOpen, setIsNewRCADialogOpen] = useState(false)
  const [selectedRCA, setSelectedRCA] = useState<any | null>(null)

  const handleRCARowClick = (data: any) => {
    setSelectedRCA(data)
  }

  const handleCloseRCAModal = () => {
    setSelectedRCA(null)
  }

  const handleOpenNewRCADialog = () => {
    setIsNewRCADialogOpen(true)
  }

  const handleCloseNewRCADialog = () => {
    setIsNewRCADialogOpen(false)
  }

  useEffect(() => {
    if (getUserDetailsRCA.id) {
      getAllRCAs(getUserDetailsRCA.id)
      fetchProjectsName(getUserDetailsRCA.id)
    }
  }, [getUserDetailsRCA.id])

  useEffect(() => {
    if (isRCACreated) {
      toast.success('RCA sent successfully')
      resetRCA()
      getAllRCAs(getUserDetailsRCA.id)
    }
  }, [isRCACreated])

  return (
    <StyledPaper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '50px',
          justifyContent: 'space-between',
          marginTop: '-25px',
        }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label='Tabs for different tables'>
          <Tab label='Plan For The Day' onClick={() => setSelectedTab(0)} />
          <Tab label='Timesheet' onClick={() => setSelectedTab(1)} />
          <Tab label='RCA' onClick={() => setSelectedTab(2)} />
        </Tabs>
        <Box sx={{ width: 'auto', padding: '5px' }}>
          <i>Priority Levels : High (Today), Medium (Tomorrow), Low (Day After)</i>
        </Box>
      </Box>
      {selectedTab === 0 && (
        <StyledPaper sx={{ padding: '1rem' }}>
          <Dialog open={isCreatingPlanForTheDay} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <FormControl>
                <InputLabel
                  id='demo-simple-select-readonly-label'
                  sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                >
                  Select Month
                </InputLabel>
                <SelectField
                  sx={{ borderRadius: '100px', width: '160px' }}
                  variant='outlined'
                  type='small'
                  label='Select Month:'
                  value={selectedPlanForTheDayMonth}
                  onChange={handleSelectPlanForTheDayMonth}
                  open={checkOpen}
                  onOpen={() => {
                    window.addEventListener('scroll', () => {
                      setCheckOpen(false)
                      return window.removeEventListener('scroll', () => {})
                    })
                  }}
                  onClick={() => setCheckOpen((prev) => !prev)}
                >
                  {AttendanceTimesheet?.map((option: any) => (
                    <StyledMenuItem key={option?.id} value={option?.id}>
                      {moment(option?.start_date).format('MMMM YYYY')}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </FormControl>
            </Box>
            <ActionButton
              variant='outlined'
              startIcon={<AddTwoToneIcon />}
              onClick={handleNewPlanDialog}
            >
              NEW PLAN FOR THE DAY
            </ActionButton>
          </Box>

          <CreatePlanDialog
            isIdsrRecentData={isIdsrRecentData}
            getIdsrRecentData={getIdsrRecentData}
            employeeRecords={employeeRecords}
            open={isNewPlanDialogOpen}
            createPlanForTheDay={createPlanForTheDay}
            AllProjectsName={AllProjectsName}
            onClose={handleCloseNewPlanDialog}
            getStatusType={getStatusType}
            getManagerDetails={getUserDetails?.manager}
            mandateData={mandateTypeData}
            getPlanForTheDayData={getPlanForTheDayData}
            getPfpId={getPfpId}
            setPfpId={setPfpId}
            isGetPlanForTheDayList={isGetPlanForTheDayList}
            isUpdatedPlanForTheDayList={isUpdatedPlanForTheDayList}
            getPlanForTheDay={getPlanForTheDay}
            resetGetPlanForTheDay={resetGetPlanForTheDay}
            resetEditPlanForTheDay={resetEditPlanForTheDay}
            editPlanForTheDay={editPlanForTheDay}
            checkPfpForm={checkPfpForm}
            setCheckPfpForm={setCheckPfpForm}
            fetchAttendanceTimesheet={fetchAttendanceTimesheet}
            getUserById={getUserById.id}
            isGetPlanForTheDayLoader={isGetPlanForTheDayLoader}
            getCcData={getCcData}
            recentIDSR={recentIDSR}
          />

          <PlanForTheDayTable
            data={getAllPlansList}
            onRowClick={handlePlanRowClick}
            fetchAttendanceData1={fetchAttendanceData1}
            isFetchingPlans={isFetchingPlans}
            getStatusType={getStatusType}
            mandateData={mandateTypeData}
            setNewPlanDialog={setNewPlanDialog}
            getPlanForTheDay={getPlanForTheDay}
            getPlanForTheDayData={getPlanForTheDayData}
            getPfpId={getPfpId}
            setPfpId={setPfpId}
            isGetPlanForTheDayList={isGetPlanForTheDayList}
            setCheckPfpForm={setCheckPfpForm}
            isUpdateLoaderPlanForTheDayList={isUpdateLoaderPlanForTheDayList}
            setCcData={setCcData}
          />
          <Modal open={!!selectedPlanForTheDay} onClose={handleClosePlanModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                maxWidth: '75%',
                bgcolor: 'background.paper',
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='close'
                onClick={handleClosePlanModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[600],
                  marginTop: '15px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box display={'flex'} justifyContent={'center'} width='100%' height={'70px'}>
                <Box width='65%' justifyContent='flex-end' display='flex'>
                  <Heading
                    style={{
                      fontSize: '18px',
                      textAlign: 'center',
                      fontFamily: 'sans-serif',
                      letterSpacing: '0px',
                      position: 'relative',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: selectedPlanForTheDay?.subject || '',
                    }}
                  />
                </Box>
                <Box width={'40%'} display={'flex'} justifyContent={'flex-end'}>
                  <Tooltip title={selectedPlanForTheDay?.approve_status === 1 ? '' : 'Approve'}>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        left: '63%',
                        top: 8,
                        marginTop: '11px',
                        width: '50px',
                        cursor:
                          selectedPlanForTheDay?.approve_status === 1 ? 'not-allowed' : 'pointer',
                      }}
                      disableRipple={selectedPlanForTheDay?.approve_status === 1}
                    >
                      {selectedPlanForTheDay?.approve_status === 1 ? <CheckedApprovedIcon /> : ''}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Tooltip
                title={copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'}
                open={hoverTooltipOpen || copyTooltipOpen}
                arrow
              >
                <IconButton
                  aria-label='copy'
                  onClick={handleCopyContent}
                  onMouseEnter={() => setHoverTooltipOpen(true)}
                  onMouseLeave={() => setHoverTooltipOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 50,
                    top: 22,
                    zIndex: 999,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Typography variant='body1' style={{ fontSize: '12px', wordBreak: 'break-word' }}>
                <div style={{ margin: '-23px 0 0 0' }}>
                  {convertTextToHTML(selectedPlanForTheDay?.body || '')}
                </div>
              </Typography>

              {selectedPlanForTheDay?.approve_status === 0
                ? selectedPlanForTheDay?.comment && (
                    <Box
                      width={'98%'}
                      padding={'15px 0 0 0'}
                      display={'flex'}
                      flexDirection={'column'}
                      flexWrap={'wrap'}
                    >
                      {/* <Typography
                      fontFamily={'Montserrat-Semibold'}
                      fontSize={'15px'}
                      marginBottom={'5px'}
                    >
                      Comment:{' '}
                    </Typography> */}
                      <Box
                        width={'100%'}
                        height={'30px'}
                        display={'flex'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                      >
                        <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'}>
                          Comment{' '}
                        </Typography>
                        <Typography
                          marginLeft={'3px'}
                          fontFamily={'Montserrat-Semibold'}
                          fontSize={'13px'}
                        >
                          {' '}
                          {`(${dayjs(selectedPlanForTheDay?.comment_submission_time).format(
                            'MM/DD/YYYY hh:mm A',
                          )})`}
                          :
                        </Typography>
                      </Box>
                      <Typography
                        width={'100%'}
                        border={'1px solid #D3D3D3'}
                        padding={'0 10px'}
                        sx={{
                          wordWrap: 'break-word',
                          fontSize: '12px',
                          borderBottomColor: 'coral',
                        }}
                      >
                        {convertTextToHTML(selectedPlanForTheDay?.comment || '')}
                      </Typography>
                    </Box>
                  )
                : ' '}
            </Box>
          </Modal>
        </StyledPaper>
      )}
      {selectedTab === 1 && (
        <StyledPaper sx={{ padding: '1rem' }}>
          <Dialog open={isCreatingIDSR} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <FormControl>
                <InputLabel
                  id='demo-simple-select-readonly-label'
                  sx={{ marginTop: -0.3, fontFamily: 'Montserrat-Medium', fontSize: '13px' }}
                >
                  Select Month
                </InputLabel>
                <SelectField
                  sx={{ borderRadius: '100px', width: '160px' }}
                  variant='outlined'
                  type='small'
                  label='Select Month:'
                  value={selectedTimeSheetMonth}
                  onChange={handleSelectTimeSheetMonth}
                  open={checkOpen}
                  onOpen={() => {
                    window.addEventListener('scroll', () => {
                      setCheckOpen(false)
                      return window.removeEventListener('scroll', () => {})
                    })
                  }}
                  onClick={() => setCheckOpen((prev) => !prev)}
                >
                  {AttendanceTimesheet?.map((option: any) => (
                    <StyledMenuItem key={option?.id} value={option?.id}>
                      {moment(option?.start_date).format('MMMM YYYY')}
                    </StyledMenuItem>
                  ))}
                </SelectField>
              </FormControl>
            </Box>
            {/* <ActionButton
              variant='outlined'
              startIcon={<AddTwoToneIcon />}
              onClick={handleOpenNewIDSRDialog}
            >
              NEW TIMESHEET
            </ActionButton> */}
          </Box>

          <CreateIDSRDialog
            employeeRecords={employeeRecords}
            getPlanForTheDay={getPlanForTheDay}
            getAllPlansList={getAllPlansList}
            getPlanForTheDayData={getPlanForTheDayData}
            open={isNewIDSRDialogOpen}
            createIDSR={createIDSR}
            AllProjectsName={AllProjectsName}
            onClose={handleCloseNewIDSRialog}
            getStatusType={getStatusType}
            getTaskStatus={getTaskStatus}
            getManagerDetails={getUserDetails?.manager}
            mandateData={mandateTypeData}
            setCheckIdsrForm={setCheckIdsrForm}
            checkIdsrForm={checkIdsrForm}
            singleIdsrId={singleIdsrId}
            editSingleIdsr={editSingleIdsr}
            resetEditSingleIdsr={resetEditSingleIdsr}
            resetGetSingleIdsr={resetGetSingleIdsr}
            getSingleIdsrData={getSingleIdsrData}
            isGetSingleIdsrList={isGetSingleIdsrList}
            isUpdatedSingleIdsrList={isUpdatedSingleIdsrList}
            isUpdateLoaderSingleIdsrList={isUpdateLoaderSingleIdsrList}
            getSingleIdsr={getSingleIdsr}
            fetchAttendanceData={fetchAttendanceData}
            userId={getUserById.id}
            selectedMonthId={selectedTimeSheetMonth}
          />

          <IDSRTable
            data={getAllIDSRsList}
            onRowClick={handleRowClick}
            fetchAttendanceData1={fetchAttendanceData1}
            isFetchingIDSRs={isFetchingIDSRs}
            getStatusType={getStatusType}
            getTaskStatus={getTaskStatus}
            mandateData={mandateTypeData}
            getMandateType={getMandateType}
            setisNewIDSRDialogOpen={setisNewIDSRDialogOpen}
            getSingleIdsr={getSingleIdsr}
            setCheckIdsrForm={setCheckIdsrForm}
            setSingleIdsrId={setSingleIdsrId}
            isUpdatedSingleIdsrList={isUpdatedSingleIdsrList}
            isUpdateLoaderSingleIdsrList={isUpdateLoaderSingleIdsrList}
          />
          <Modal open={!!selectedIDSR} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                maxWidth: '75%',
                bgcolor: 'background.paper',
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='close'
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[600],
                  marginTop: '15px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Tooltip
                title={copyTooltipOpen ? 'Copied!' : 'Copy to Clipboard'}
                open={hoverTooltipOpen || copyTooltipOpen}
                arrow
              >
                <IconButton
                  aria-label='copy'
                  onClick={handleCopyTimesheetContent}
                  onMouseEnter={() => setHoverTooltipOpen(true)}
                  onMouseLeave={() => setHoverTooltipOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 50,
                    top: 22,
                    zIndex: 999,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
                dangerouslySetInnerHTML={{
                  __html: selectedIDSR?.subject || '',
                }}
              />
              <Box display='flex' justifyContent='center' alignItems='center' marginBottom='0.5rem'>
                {/* <Typography variant='body1' fontSize='13px'>
                  Project Name:{' '}
                  {selectedIDSR?.project_name === 'Other'
                    ? selectedIDSR?.other_project_name
                    : selectedIDSR?.project_name}
                </Typography>
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} /> */}
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  Total time spent: {selectedIDSR?.time_spent} mins
                </Typography>
                {/* <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} />
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  No. of PR Raised: {selectedIDSR?.no_of_pr_raised ?? 0}
                </Typography>
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} />
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  No. of PR Approved: {selectedIDSR?.no_of_pr_approved ?? 0}
                </Typography>
                <Divider orientation='vertical' flexItem sx={{ marginLeft: '10px' }} />
                <Typography variant='body1' fontSize='13px' marginLeft='10px'>
                  No. of Reworked PR: {selectedIDSR?.no_of_reworked_pr ?? 0}
                </Typography> */}
              </Box>
              <Divider />
              <Typography variant='body1' style={{ fontSize: '12px', wordBreak: 'break-word' }}>
                <div>{convertTextToHTML(selectedIDSR?.body || '')}</div>
              </Typography>
              {selectedIDSR?.comment && (
                <Box width={'98%'} paddingTop={'15px'}>
                  {/* <Typography
                    fontFamily={'Montserrat-Semibold'}
                    fontSize={'15px'}
                    marginBottom={'5px'}
                  >
                    Comment:{' '}
                  </Typography> */}
                  <Box
                    width={'100%'}
                    height={'30px'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Typography fontFamily={'Montserrat-Semibold'} fontSize={'15px'}>
                      Comment{' '}
                    </Typography>
                    <Typography
                      marginLeft={'3px'}
                      fontFamily={'Montserrat-Semibold'}
                      fontSize={'13px'}
                    >
                      {' '}
                      {`(${dayjs(selectedPlanForTheDay?.comment_submission_time).format(
                        'MM/DD/YYYY hh:mm A',
                      )})`}
                      :
                    </Typography>
                  </Box>
                  <Typography
                    border={'1px solid #D3D3D3'}
                    padding={'0 10px'}
                    width={'100%'}
                    sx={{
                      wordWrap: 'break-word',
                      fontSize: '12px',
                      borderBottomColor: 'coral',
                    }}
                  >
                    {convertTextToHTML(selectedIDSR?.comment || '')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Modal>
        </StyledPaper>
      )}

      {selectedTab === 2 && (
        <StyledPaper>
          <Box display={'flex'} alignItems={'center'} width={'100%'} sx={{ marginTop: '-20px' }}>
            <Box width={'55%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
              <HeaderHeading>My RCAs</HeaderHeading>
            </Box>
            <Box width={'45%'} display={'flex'} justifyContent={'flex-end'} marginTop='10px'>
              <ActionButton
                variant='outlined'
                startIcon={<AddTwoToneIcon sx={{ width: 24, height: 24 }} />}
                onClick={handleOpenNewRCADialog}
              >
                NEW RCA
              </ActionButton>
              <CreateRcaDialog
                open={isNewRCADialogOpen}
                createRCA={createRCA}
                AllProjectsName={AllProjectsName}
                onClose={handleCloseNewRCADialog}
              />
            </Box>
          </Box>
          <Dialog open={isCreatingRCA} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
          <TableContainer sx={{ margin: '15px 0' }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>DATE</StyledTableCell>
                  <StyledTableCell>SUBJECT</StyledTableCell>
                  <StyledTableCell>MANAGER</StyledTableCell>
                  <StyledTableCell>PROJECT</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {getAllRCAsList?.length ? (
                <TableBody>
                  {getAllRCAsList.map((data: any) => (
                    <StyledTableRow
                      key={`${data?.id}`}
                      onClick={() => handleRCARowClick(data)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <StyledTableCell component='th' scope='row'>
                        {`${new Date(data.created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}`}{' '}
                      </StyledTableCell>
                      <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                      <StyledTableCell align='left'>{data.manager_name}</StyledTableCell>
                      <StyledTableCell align='left'>
                        {data.project_name === 'Other'
                          ? data.other_project_name
                          : data.project_name}
                      </StyledTableCell>{' '}
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align='center' colSpan={10}>
                      <Typography align='center' variant='subtitle1' sx={{ color: '#483f3f' }}>
                        No RCA found
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Modal open={!!selectedRCA} onClose={handleCloseRCAModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70vw',
                maxWidth: '75%',
                bgcolor: 'background.paper',
                p: 4,
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='close'
                onClick={handleCloseRCAModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[600],
                  marginTop: '15px',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Heading
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  letterSpacing: '0px',
                  position: 'relative',
                }}
              >
                {selectedRCA?.subject}
              </Heading>
              <Typography variant='body1' style={{ fontSize: '12px' }}>
                <div dangerouslySetInnerHTML={{ __html: selectedRCA?.body }}></div>
              </Typography>
            </Box>
          </Modal>
        </StyledPaper>
      )}
    </StyledPaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isIdsrRecentData: employeePortalUI.getAllEmpPortalUI(state).isIdsrRecentData,

    getIdsrRecentData: employeePortalEntity.getEmployeePortal(state).getIdsrRecentData,
    // getIdsrRecentData:
    employeeRecords: employeeDataRequest.getAllEmployeesList(state).getAllEmployeesDetails,
    getUserDetails: dashboardEntity.getDashboard(state).getUserDetails,
    isCreatingIDSR: attendanceUI.getEmpAttendanceList(state).isCreatingIDSR,
    isFetchingIDSRs: attendanceUI.getEmpAttendanceList(state).isFetchingIDSRs,
    isIDSRCreated: attendanceUI.getEmpAttendanceList(state).isIDSRCreated,
    getAllIDSRsList: fetchIDSRs.getAllIDSRList(state).fetchIDSRs,
    getUserById: dashboardEntity.getDashboard(state).getUserDetails,
    AttendanceTimesheet: attendanceEntity.getAllAttendance(state).getAttendanceTimesheet,
    AllProjectsName: fetchRCAs.getAllIDSRList(state).fetchProjects,
    getAllPlansList: fetchPlans.getAllPlansList(state).fetchPlansForTheDay,
    getStatusType: fetchStatusType.getStatusTypeList(state).fetchStatusType,
    getTaskStatus: fetchStatusType.getStatusTypeList(state).fetchTaskStatus,
    isCreatingPlanForTheDay: attendanceUI.getEmpAttendanceList(state).isCreatingPlanForTheDay,
    isPlanForTheDayCreated: attendanceUI.getEmpAttendanceList(state).isPlanForTheDayCreated,
    isFetchingPlans: attendanceUI.getEmpAttendanceList(state).isFetchingPlanForTheDay,
    mandateTypeData: projectManagementEntity.getAllProjectManagement(state).getAllMandate,
    getPlanForTheDayData: attendanceEntity.getAllAttendance(state).getPlanForTheDayData,
    isGetPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isGetPlanForTheDayList,
    isGetPlanForTheDaisGetPlanForTheDayLoaderyLoader:
      attendanceUI.getEmpAttendanceList(state).isGetPlanForTheDayLoader,

    isUpdatedPlanForTheDayList: attendanceUI.getEmpAttendanceList(state).isUpdatePlanForTheDayList,
    isUpdateLoaderPlanForTheDayList:
      attendanceUI.getEmpAttendanceList(state).isUpdateLoaderPlanForTheDayList,

    getSingleIdsrData: attendanceEntity.getAllAttendance(state).getSingleIdsrData,
    isGetSingleIdsrList: attendanceUI.getEmpAttendanceList(state).isGetSingleIdsrList,
    isUpdatedSingleIdsrList: attendanceUI.getEmpAttendanceList(state).isUpdateSingleList,
    isUpdateLoaderSingleIdsrList: attendanceUI.getEmpAttendanceList(state).isUpdateLoaderIdsrList,

    getAllRCAsList: fetchRCAs.getAllRCAsList(state).fetchRCAs,
    getUserDetailsRCA: dashboardEntity.getDashboard(state).getUserDetails,
    isCreatingRCA: attendanceUI.getEmpAttendanceList(state).isCreatingRCA,
    isRCACreated: attendanceUI.getEmpAttendanceList(state).isRCACreated,
    AllProjectsNameForRCA: fetchRCAs.getAllIDSRList(state).fetchProjects,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    recentIDSR: () => dispatch(fetchIdsrRecent.request()),
    createIDSR: (data: any) => dispatch(createNewIDSR.request({ data })),
    resetIDSR: () => dispatch(createNewIDSR.reset()),
    getAllIDSRs: (userId: any) => dispatch(getIDSRs.request({ userId })),
    fetchAttendanceData: (data: any) => dispatch(getIDSRs.request(data)),
    fetchAttendanceTimesheet: (userId: any) => dispatch(getAttendanceTimesheet.request({ userId })),
    fetchProjectsName: (userId: any) => dispatch(fetchProjectsName.request({ userId })),
    fetchPlansForTheDay: (data: any) => dispatch(getPlans.request(data)),
    fetchStatusType: () => dispatch(getStatusType.request()),
    fetchTaskStatus: () => dispatch(getTaskStatus.request()),
    createPlanForTheDay: (data: any) => dispatch(createNewPlanForTheDay.request({ data })),
    resetPlanForTheDay: () => dispatch(createNewPlanForTheDay.reset()),
    getMandateType: (data: any) => dispatch(fetchMandateType.request(data)),
    getPlanForTheDay: (data: any) => dispatch(getPlanForTheDay.request(data)),
    editPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.request(data)),
    resetEditPlanForTheDay: (data: any) => dispatch(editPlanForTheDay.reset()),
    resetGetPlanForTheDay: () => dispatch(getPlanForTheDay.reset()),

    getSingleIdsr: (data: any) => dispatch(getSingleIdsr.request(data)),
    editSingleIdsr: (data: any) => dispatch(editSingleIdsr.request(data)),
    resetEditSingleIdsr: (data: any) => dispatch(editSingleIdsr.reset()),
    resetGetSingleIdsr: () => dispatch(getSingleIdsr.reset()),

    getAllRCAs: (userId: any) => dispatch(getRCAs.request({ userId })),
    createRCA: (data: any) => dispatch(createNewRCA.request({ data })),
    resetRCA: () => dispatch(createNewRCA.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IDSR)
