import { call, take, takeLatest } from 'redux-saga/effects'
import { CompanyIdTypes, LogoApiResponse, actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  FETCH_ASSET,
  FETCH_BACKINFO,
  FETCH_BASICINFO,
  FETCH_HOLIDAYS,
  FETCH_LEAVE_DETAILS,
  FETCH_MARITIAL_STATUS,
  FETCH_SR,
  FETCH_DRS_DATA,
  FETCH_SUB_DRS_DATA,
  FETCH_USERS,
  FETCH_DEPARTMENT_LIST,
  FETCH_ISSUE_TYPE_LIST,
  USER_INFO,
  HOME_PAGE_INFO,
  FETCH_USER_BY_ID,
  FETCH_REF_BY_ID,
  GET_LEAVE_TIMESHEET,
  GET_LEAVE_DATA,
  ACCEPT_LEAVE_DATA,
  REJECT_LEAVE_DATA,
  FETCH_PROJECT_DATA,
  FETCH_HIGHLIGHTS,
  FETCH_ASSIGNED_SR,
  MANAGER_VIEW_DATA,
  FETCH_DOC_URL,
  COMPANY_LOGO,
  COMPANY_ID,
  GET_CLIENT_MEMBER,
  GET_TEAM_MANDATES,
  GET_DELAYED_GRAPH,
  GET_DELAYED_TABLE,
} from '../actions/actiontypes'
const { REQUEST, GET_USER_DATA } = actionTypes

//write sagas function

function* handleGetUserData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserDataEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_USERS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_USERS)
  }
}

function* handleClientMemberData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getClientMembers,
    )
    yield sendPayload(apiResponse, GET_CLIENT_MEMBER)
  } catch (e) {
    yield sendPayloadFailure(e, GET_CLIENT_MEMBER)
  }
}

function* handleTeamMandatesData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getTeamMandates,
    )
    yield sendPayload(apiResponse, GET_TEAM_MANDATES)
  } catch (e) {
    yield sendPayloadFailure(e, GET_TEAM_MANDATES)
  }
}

function* handleDelayedGrapData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDelayedGraps,
      data,
    )
    yield sendPayload(apiResponse, GET_DELAYED_GRAPH)
  } catch (e) {
    yield sendPayloadFailure(e, GET_DELAYED_GRAPH)
  }
}

function* handleDelayedTableData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDelayedTableEndPoint,
      data,
    )
    yield sendPayload(apiResponse, GET_DELAYED_TABLE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_DELAYED_TABLE)
  }
}

function* handleGetBackInfoData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getBackInfoDataEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_BACKINFO)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_BACKINFO)
  }
}

function* handleHolidaysData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getHolidaysEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_HOLIDAYS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_HOLIDAYS)
  }
}

function* handleHighlightsData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getHighlightsEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_HIGHLIGHTS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_HIGHLIGHTS)
  }
}

function* handleAssetData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getAssetsEndPoint)
    yield sendPayload(apiResponse, FETCH_ASSET)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ASSET)
  }
}

function* handleBasicInfoData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getBasicInfoPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_BASICINFO)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_BASICINFO)
  }
}

function* handleLeaveData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeaveDataEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, GET_LEAVE_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_LEAVE_DATA)
  }
}

function* handleAcceptLeaveData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAcceptLeaveDataEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, ACCEPT_LEAVE_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, ACCEPT_LEAVE_DATA)
  }
}

function* handleRejectLeaveData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getRejectLeaveDataEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, REJECT_LEAVE_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, REJECT_LEAVE_DATA)
  }
}

function* handleSRData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getSREndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_SR)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_SR)
  }
}

function* handleAssignedSRData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAssignedSREndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_ASSIGNED_SR)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ASSIGNED_SR)
  }
}

function* handleDrsData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getDrsEndPoint)
    yield sendPayload(apiResponse, FETCH_DRS_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_DRS_DATA)
  }
}

function* handleSubDrsData(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getSubDrsEndPoint,data)
    yield sendPayload(apiResponse, FETCH_SUB_DRS_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_SUB_DRS_DATA)
  }
}

function* handleGetMaritialStatus() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getMaritalStatusEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_MARITIAL_STATUS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_MARITIAL_STATUS)
  }
}

function* handleGetLeaveDetails(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeaveDetailsEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_LEAVE_DETAILS)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_LEAVE_DETAILS)
  }
}

function* handleDepartmentList() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getDepartmentListEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_DEPARTMENT_LIST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_DEPARTMENT_LIST)
  }
}

function* handleLeaveTimesheets() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeaveTimesheetEndPoint,
    )
    yield sendPayload(apiResponse, GET_LEAVE_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, GET_LEAVE_TIMESHEET)
  }
}

function* handleUserDetails() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getUserDetails)
    yield sendPayload(apiResponse, USER_INFO)
  } catch (e) {
    yield sendPayloadFailure(e, USER_INFO)
  }
}

function* handleHomePageDetails() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getHomePageDetails)
    yield sendPayload(apiResponse, HOME_PAGE_INFO)
  } catch (e) {
    yield sendPayloadFailure(e, HOME_PAGE_INFO)
  }
}

function* handleGetIssueTypeList(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getIssueTypeListEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_ISSUE_TYPE_LIST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ISSUE_TYPE_LIST)
  }
}

function* handleProjectDetails() {
  try {// function* handleGetIssueTypeList() {
    //   try {
    //     const apiResponse: Generator<string, number, string> = yield call(
    //       ApiService.getIssueTypeListEndPoint,
    //     )
    //     yield sendPayload(apiResponse, FETCH_ISSUE_TYPE_LIST)
    //   } catch (e) {
    //     yield sendPayloadFailure(e, FETCH_ISSUE_TYPE_LIST)
    //   }
    // }
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getProjectDetailsEndPoint,
    )
    yield sendPayload(apiResponse, FETCH_PROJECT_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_PROJECT_DATA)
  }
}

function* getUserDetailsById(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserByIdEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_USER_BY_ID)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_USER_BY_ID)
  }
}

function* getRefDataById(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getReferredByIdEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_REF_BY_ID)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_REF_BY_ID)
  }
}

function* handleManagerViewData(data: any){
try{
  const apiResponse: Generator<string,number ,string> = yield call(
    ApiService.getManagerViewData,
    data,
  )
  yield sendPayload(apiResponse,MANAGER_VIEW_DATA)
} catch (e){
  yield sendPayloadFailure(e,MANAGER_VIEW_DATA)
}
}

function* handleDocURL(data: any){
  try{
    const apiResponse: Generator<string,number ,string> = yield call(
      ApiService.getDocURL,
      data,
    )
    yield sendPayload(apiResponse,FETCH_DOC_URL)
  } catch (e){
    yield sendPayloadFailure(e,FETCH_DOC_URL)
  }
  }

  function* handleCompanyLogo(data: LogoApiResponse){
    try{
      const apiResponse: Generator<string,number ,string> = yield call(
        ApiService.getCompanyLogo,
        data,
      )
      yield sendPayload(apiResponse,COMPANY_LOGO)
    } catch (e){
      yield sendPayloadFailure(e,COMPANY_LOGO)
    }
    }

    function* handleCompanyId(){
      try{
        const apiResponse: Generator<string,number ,string> = yield call(
          ApiService.getCompanyId,
          
        )
        yield sendPayload(apiResponse,COMPANY_ID)
      } catch (e){
        yield sendPayloadFailure(e,COMPANY_ID)
      }
      }



export const sagas = {
  //watcher come here
  watchGetUserData: takeLatest(FETCH_USERS[REQUEST], handleGetUserData),
  watchGetBackInfoData: takeLatest(FETCH_BACKINFO[REQUEST], handleGetBackInfoData),
  watchGetHolidaysData: takeLatest(FETCH_HOLIDAYS[REQUEST], handleHolidaysData),
  watchGetAssetData: takeLatest(FETCH_ASSET[REQUEST], handleAssetData),
  watchGetBasicInfoData: takeLatest(FETCH_BASICINFO[REQUEST], handleBasicInfoData),
  watchGetSRData: takeLatest(FETCH_SR[REQUEST], handleSRData),
  watchGetAssignedSRData: takeLatest(FETCH_ASSIGNED_SR[REQUEST], handleAssignedSRData),
  watchGetMaritalStatus: takeLatest(FETCH_MARITIAL_STATUS[REQUEST], handleGetMaritialStatus),
  watchGetLeaveDetails: takeLatest(FETCH_LEAVE_DETAILS[REQUEST], handleGetLeaveDetails),
  watchDepartmentList: takeLatest(FETCH_DEPARTMENT_LIST[REQUEST], handleDepartmentList),
  watchIssueTypeList: takeLatest(FETCH_ISSUE_TYPE_LIST[REQUEST], handleGetIssueTypeList),
  watchUserDetails: takeLatest(USER_INFO[REQUEST], handleUserDetails),
  watchHomeUserDetails: takeLatest(HOME_PAGE_INFO[REQUEST], handleHomePageDetails),
  watchUserType: takeLatest(FETCH_USER_BY_ID[REQUEST], getUserDetailsById),
  watchRefUserType: takeLatest(FETCH_REF_BY_ID[REQUEST], getRefDataById),
  watchDrsData: takeLatest(FETCH_DRS_DATA[REQUEST], handleDrsData),
  watchSubDrsData: takeLatest(FETCH_SUB_DRS_DATA[REQUEST], handleSubDrsData),
  watchLeaveTimesheets: takeLatest(GET_LEAVE_TIMESHEET[REQUEST], handleLeaveTimesheets),
  watchLeaveData: takeLatest(GET_LEAVE_DATA[REQUEST], handleLeaveData),
  watchAcceptLeaveData: takeLatest(ACCEPT_LEAVE_DATA[REQUEST], handleAcceptLeaveData),
  watchRejectLeaveData: takeLatest(REJECT_LEAVE_DATA[REQUEST], handleRejectLeaveData),
  watchProjectDetailsType: takeLatest(FETCH_PROJECT_DATA[REQUEST], handleProjectDetails),
  watchHighlightsDataType: takeLatest(FETCH_HIGHLIGHTS[REQUEST], handleHighlightsData),
  watchHandleManagerViewData: takeLatest(MANAGER_VIEW_DATA[REQUEST], handleManagerViewData),
  watchHandleDocURLData: takeLatest(FETCH_DOC_URL[REQUEST],handleDocURL),
  watchHandleCompanyLogo: takeLatest(COMPANY_LOGO[REQUEST],handleCompanyLogo),
  watchHandleCompanyId: takeLatest(COMPANY_ID[REQUEST],handleCompanyId),
  watchHandleClientMemberData: takeLatest(GET_CLIENT_MEMBER[REQUEST], handleClientMemberData),
  watchHandleTeamMandates: takeLatest(GET_TEAM_MANDATES[REQUEST], handleTeamMandatesData),
  watchHandleDelayed: takeLatest(GET_DELAYED_GRAPH[REQUEST], handleDelayedGrapData),
  watchHandleDelayedTable: takeLatest(GET_DELAYED_TABLE[REQUEST], handleDelayedTableData),

}
