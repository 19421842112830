import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  ACCEPT_LEAVE_DATA,
  ADD_NEW_COMMENT,
  COMPANY_ID,
  COMPANY_LOGO,
  FETCH_ASSET,
  FETCH_BACKINFO,
  FETCH_BASICINFO,
  FETCH_DOC_URL,
  FETCH_DRS_DATA,
  FETCH_EMPINFO,
  FETCH_HIGHLIGHTS,
  FETCH_HOLIDAYS,
  FETCH_LEAVE_DETAILS,
  FETCH_PROJECT_DATA,
  FETCH_SR,
  FETCH_SUB_DRS_DATA,
  FETCH_USERS,
  GET_CLIENT_MEMBER,
  GET_LEAVE_DATA,
  GET_LEAVE_TIMESHEET,
  GET_TEAM_MANDATES,
  MANAGER_VIEW_DATA,
  REJECT_LEAVE_DATA,
  RESET,
  GET_DELAYED_GRAPH,
  GET_DELAYED_TABLE,
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE, GET_USER_DATA } = actionTypes

const ui = () => {
  const isGetUserDataDone = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_USER_DATA[SUCCESS]:
        return true
      case GET_USER_DATA[FAILURE]:
      case GET_USER_DATA[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isClientMembersData = (state = [], action: Actions) => {
    switch (action.type) {
      case GET_CLIENT_MEMBER[SUCCESS]:
          return false
        case GET_CLIENT_MEMBER[FAILURE]:
          return false
        case GET_CLIENT_MEMBER[REQUEST]:
          return true
      default:
        return state
    }
  }
  const isTeamMandateData = (state = [], action: Actions) => {
    switch (action.type) {
      case GET_TEAM_MANDATES[SUCCESS]:
          return false
        case GET_TEAM_MANDATES[FAILURE]:
          return false
        case GET_TEAM_MANDATES[REQUEST]:
          return true
      default:
        return state
    }
  }

  const isDelayedGraphData = (state = [], action: Actions) => {
    switch (action.type) {
      case GET_DELAYED_GRAPH[SUCCESS]:
          return false
        case GET_DELAYED_GRAPH[FAILURE]:
          return false
        case GET_DELAYED_GRAPH[REQUEST]:
          return true
      default:
        return state
    }
  }

  const isDelayedTableData = (state = [], action: Actions) => {
    switch (action.type) {
      case GET_DELAYED_TABLE[SUCCESS]:
          return false
        case GET_DELAYED_TABLE[FAILURE]:
          return false
        case GET_DELAYED_TABLE[REQUEST]:
          return true
      default:
        return state
    }
  }

  const isGetBackInfoData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_BACKINFO[SUCCESS]:
        return false
      case FETCH_BACKINFO[FAILURE]:
        return false
      case FETCH_BACKINFO[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGetEmpInfoData = (state = true, action: Actions) => {
    switch (action.type) {
      case FETCH_EMPINFO[SUCCESS]:
        return false
      case FETCH_EMPINFO[FAILURE]:
        return false
      case FETCH_EMPINFO[REQUEST]:
        return true
      case FETCH_EMPINFO[RESET]:
        return false
      default:
        return state
    }
  }

  const isGetEmpInfoDataSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_EMPINFO[SUCCESS]:
        return true
      case FETCH_EMPINFO[FAILURE]:
        return false
      case FETCH_EMPINFO[REQUEST]:
        return false
      default:
        return state
    }
  }
  const isGetUserData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_USERS[SUCCESS]:
        return false
      case FETCH_USERS[FAILURE]:
        return false
      case FETCH_USERS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isServiceRequestData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_SR[SUCCESS]:
        return false
      case FETCH_SR[FAILURE]:
        return false
      case FETCH_SR[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isLeaveData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_LEAVE_DETAILS[SUCCESS]:
        return false
      case FETCH_LEAVE_DETAILS[FAILURE]:
        return false
      case FETCH_LEAVE_DETAILS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isHomePageData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_LEAVE_DETAILS[SUCCESS]:
        return false
      case FETCH_LEAVE_DETAILS[FAILURE]:
        return false
      case FETCH_LEAVE_DETAILS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isAssetsData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ASSET[SUCCESS]:
        return false
      case FETCH_ASSET[FAILURE]:
        return false
      case FETCH_ASSET[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isBasicInfoData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_BASICINFO[SUCCESS]:
        return false
      case FETCH_BASICINFO[FAILURE]:
        return false
      case FETCH_BASICINFO[REQUEST]:
        return true
      case FETCH_BASICINFO[RESET]:
        return false
      default:
        return state
    }
  }

  const isHolidaysData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_HOLIDAYS[SUCCESS]:
        return false
      case FETCH_HOLIDAYS[FAILURE]:
        return false
      case FETCH_HOLIDAYS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const getHighlightsData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_HIGHLIGHTS[SUCCESS]:
        return false
      case FETCH_HIGHLIGHTS[FAILURE]:
        return false
      case FETCH_HIGHLIGHTS[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isDrsData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_DRS_DATA[SUCCESS]:
        return false
      case FETCH_DRS_DATA[FAILURE]:
        return false
      case FETCH_DRS_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isSubDrsData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_SUB_DRS_DATA[SUCCESS]:
        return false
      case FETCH_SUB_DRS_DATA[FAILURE]:
        return false
      case FETCH_SUB_DRS_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isLeaveTimesheets = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_LEAVE_TIMESHEET[SUCCESS]:
        return false
      case GET_LEAVE_TIMESHEET[FAILURE]:
        return false
      case GET_LEAVE_TIMESHEET[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isLeavesData = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_LEAVE_DATA[SUCCESS]:
        return false
      case GET_LEAVE_DATA[FAILURE]:
        return false
      case GET_LEAVE_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isAcceptLeaveData = (state = false, action: Actions) => {
    switch (action.type) {
      case ACCEPT_LEAVE_DATA[SUCCESS]:
        return false
      case ACCEPT_LEAVE_DATA[FAILURE]:
        return false
      case ACCEPT_LEAVE_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isRejectLeaveData = (state = false, action: Actions) => {
    switch (action.type) {
      case REJECT_LEAVE_DATA[SUCCESS]:
        return false
      case REJECT_LEAVE_DATA[FAILURE]:
        return false
      case REJECT_LEAVE_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }


  const isGettingLeaveData = (state = [], action: Actions) => {
    switch (action.type) {
      case GET_LEAVE_TIMESHEET[SUCCESS]:
        return true
      case GET_LEAVE_TIMESHEET[REQUEST]:
        return false
      default:
        return false
    }
  }

  const isLeaveAcceptOrReject = (state = [], action: Actions) => {
    switch (action.type) {
      case ACCEPT_LEAVE_DATA[SUCCESS]:
        return true
      case ACCEPT_LEAVE_DATA[REQUEST]:
        return false
      default:
        return false
    }
  }

  const getProjectData = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PROJECT_DATA[SUCCESS]:
        return false
      case FETCH_PROJECT_DATA[FAILURE]:
        return false
      case FETCH_PROJECT_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }


  const isCommentData = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_NEW_COMMENT[SUCCESS]:
        return false
      case ADD_NEW_COMMENT[FAILURE]:
        return false
      case ADD_NEW_COMMENT[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isURLSave = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_DOC_URL[SUCCESS]:
        return true
      case FETCH_DOC_URL[FAILURE]:
      case FETCH_DOC_URL[RESET]:
        return false
      default:
        return state
    }
  }

  const isGettingManagerViewData = (state = false, action: Actions) => {
    switch (action.type) {
      case MANAGER_VIEW_DATA[SUCCESS]:
        return false
        case MANAGER_VIEW_DATA[FAILURE]:
      case MANAGER_VIEW_DATA[REQUEST]:
        return true
      default:
        return state
    }
  }
  const isGettingCompaniesId = (state = false, action: Actions) => {
    switch (action.type) {
      case COMPANY_ID[SUCCESS]:
        return true
      case COMPANY_ID[RESET]:
        return false
      default:
        return state
    }
  }
  const isGettingCompanyLogo = (state = false, action: Actions) => {
    switch (action.type) {
      case COMPANY_LOGO[SUCCESS]:
        return false
      case COMPANY_LOGO[REQUEST]:
        return true
      default:
        return state
    }
  }
 

  return combineReducers({
    isGetUserDataDone,
    isGetBackInfoData,
    isGetEmpInfoData,
    isGetUserData,
    isServiceRequestData,
    isLeaveData,
    isHomePageData,
    isAssetsData,
    isBasicInfoData,
    isHolidaysData,
    getHighlightsData,
    isCommentData,
    isGetEmpInfoDataSuccess,
    isDrsData,
    isSubDrsData,
    isLeaveTimesheets,
    isLeavesData,
    isGettingLeaveData,
    isAcceptLeaveData,
    isRejectLeaveData,
    isLeaveAcceptOrReject,
    getProjectData,
    isURLSave,
    isGettingManagerViewData,
    isGettingCompaniesId,
    isGettingCompanyLogo,
    isClientMembersData,
    isTeamMandateData,
    isDelayedGraphData,
    isDelayedTableData
    // resetLeavesData
  })
}

export default ui

export const getDashboard = (state: RootState) => state.ui.dashboard
