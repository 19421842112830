import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'
import dayjs from 'dayjs'

const parseHTMLTable = (htmlString: string, name: string) => {
  // Create a DOMParser instance to parse the HTML string
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  // Extract table rows
  const rows = Array.from(doc.querySelectorAll('tbody tr'))

  // Map over rows and extract relevant fields
  return rows.map((row) => {
    const columns = row.querySelectorAll('td')

    return {
      'Project Name': columns[0]?.textContent || '',
      'Mandate Type': columns[1]?.textContent || '',
      'Task Description': columns[2]?.innerHTML || '', // Use innerHTML to preserve structure
      'Task Status': columns[3]?.textContent || '',
      Priority: columns[4]?.textContent || '',
      'Time Spent (mins)': columns[5]?.textContent || '',
      name: name || '',
    }
  })
}

const ProductivityMatrixChart = (props: any) => {
  const { chartData, showResults } = props
  const [maxLength, setMaxLength] = useState(0)
  const data = chartData?.users?.map((value: any) => parseHTMLTable(value?.body, value?.name)) ?? []

  const roundedTimeSpentInHoursArray = data?.map((value: any) => {
    return value?.map((task: any) => {
      const timeInMinutes = Number(task['Time Spent (mins)']) // Convert string to number
      const timeInHours = timeInMinutes / 60 // Convert minutes to hours
      return {
        hours: Number(task['Time Spent (mins)']), // Round off to nearest whole number
        description: task['Task Description'] || '', // Add task description here
        name: task['name'],
      }
    })
  })

  useEffect(() => {
    let mx = 0
    if (roundedTimeSpentInHoursArray?.length > 0) {
      roundedTimeSpentInHoursArray.map((value: any) => {
        if (mx < value?.length) mx = value?.length
      })
      setMaxLength(mx)
    }
  }, [roundedTimeSpentInHoursArray])

  useEffect(() => {
    const chartDom = document.getElementById('main')
    const myChart = echarts.init(chartDom)
    let option

    const dateLabels = chartData?.users?.map((item: { IDSR_date: any }) => item.IDSR_date) ?? []

    console.log("dateLabels",)

    const series = Array.from({ length: maxLength }).map((value: any, sid: number) => {
      return {
        name: `Task ${sid + 1}`,
        type: 'bar',
        stack: 'total',
        barWidth: '60%',
        label: {
          show: true,
        },
        data: roundedTimeSpentInHoursArray.map((d: any) => d[sid]?.hours), // Show hours in the chart
      }
    })

    const tooltipOptions = {
      trigger: 'item', // Trigger tooltip on item hover
      formatter: (params: any) => {
        // Get the task description from the series data
        const taskIndex = params.dataIndex
        const taskData = roundedTimeSpentInHoursArray[taskIndex]?.[params.seriesIndex]

        return `
          <div style="display: flex; flex-direction: column; max-width: 350px; word-wrap: break-word; overflow-wrap: break-word; overflow: hidden;">
            <div>
              <strong>Emp Name</strong>: ${taskData?.name}
            </div>
            <div>
              <strong>Time Spent</strong>: ${params.value} mins (${(params.value / 60).toFixed(2)} hrs)
            </div>
            <div style="display: flex; align-items: flex-start;">
                  <div>
              <strong style="margin-right: 5px;">Task Description:</strong></div>
              <span style="margin-top:-15px;margin-left: 5px; white-space: normal; word-break: break-word;">
                ${taskData?.description || 'N/A'}
              </span>
            </div>
          </div>
        `
      },
      backgroundColor: '#ffffff', // Tooltip background color
      borderColor: '#ccc', // Tooltip border color
      borderWidth: 1,
      padding: 10,
    }

    // Define chart options with dynamic X-axis date range, Y-axis hours, and tooltip
    option = {
      tooltip: tooltipOptions,
      // legend: {
      //   selectedMode: false,
      //   orient: 'horizontal', // Horizontal orientation for legend
      //   left: 'center', // Center the legend horizontally
      //   bottom: 0,
      // },
      grid: {
        left: 100,
        right: 100,
        top: 50,
        bottom: 50,
      },
      xAxis: {
        type: 'category', // Category for dates
        data: dateLabels, // Dynamic date labels for X-axis
        axisLabel: {
          formatter: (value: any) => dayjs(value).format('MMM D'), // Custom date formatting
        },
      },
      yAxis: {
        type: 'value', // Use 'value' for numerical representation
        name: 'Minutes',
        nameTextStyle: {
          padding: [0, 0, 10, 0], // Padding for the Y-axis name
        },
      },
      series: series,
    }

    option && myChart.setOption(option)

    // Cleanup chart on unmount
    return () => {
      myChart.dispose()
    }
  }, [showResults, maxLength])

  return (
    <div>
      {/* This is where the chart will be rendered */}
      <div id='main' style={{ width: '100%', height: '400px' }}></div>
    </div>
  )
}

export default ProductivityMatrixChart
