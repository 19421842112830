import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_ATTENDANCE,
  FETCH_ATTENDANCE_TIMESHEET,
  CREATE_RCA,
  RESET,
  CREATE_IDSR,
  FETCH_IDSR,
  CREATE_PLAN_FOR_THE_DAY,
  FETCH_PLANS_FOR_THE_DAY,
  ADD_COMMENT_TIMESHEET,
  ADD_COMMENT_PLANFORTHEDAY,
  APPROVE_PLANFORTHEDAY,
  GET_PLANFORTHEDAY,
  EDIT_PLANFORTHEDAY,
  GET_SINGLE_IDSR,
  EDIT_SINGLE_IDSR
} from '../../actions/actiontypes'

const { SUCCESS, REQUEST, FAILURE } = actionTypes

const ui = () => {

  const isCreateCommentForPlanForTheDayLoader = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_COMMENT_PLANFORTHEDAY[SUCCESS]:
        return false
      case ADD_COMMENT_PLANFORTHEDAY[FAILURE]:
        return false;
      case ADD_COMMENT_PLANFORTHEDAY[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isCreateCommentForPlanForTheDaySuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_COMMENT_PLANFORTHEDAY[SUCCESS]:
        return true
      case ADD_COMMENT_PLANFORTHEDAY[FAILURE]:
      case ADD_COMMENT_PLANFORTHEDAY[RESET]:
        return false;
      case ADD_COMMENT_PLANFORTHEDAY[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isCreateCommentForTimesheetLoader = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_COMMENT_TIMESHEET[SUCCESS]:
        return false
      case ADD_COMMENT_TIMESHEET[FAILURE]:
        return false;
      case ADD_COMMENT_TIMESHEET[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isCreateCommentForTimesheetSuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case ADD_COMMENT_TIMESHEET[SUCCESS]:
        return true
      case ADD_COMMENT_TIMESHEET[FAILURE]:
      case ADD_COMMENT_TIMESHEET[RESET]:
        return false;
      case ADD_COMMENT_TIMESHEET[REQUEST]:
        return false
      default:
        return state
    }
  }


  const isApprovingPlanForTheDay = (state = false, action: Actions) => {
    switch (action.type) {
      case APPROVE_PLANFORTHEDAY[SUCCESS]:
        return false
      case APPROVE_PLANFORTHEDAY[FAILURE]:
      case APPROVE_PLANFORTHEDAY[RESET]:
        return false;
      case APPROVE_PLANFORTHEDAY[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isApprovingPlanForTheDaySuccess = (state = false, action: Actions) => {
    switch (action.type) {
      case APPROVE_PLANFORTHEDAY[SUCCESS]:
        return true
      case APPROVE_PLANFORTHEDAY[FAILURE]:
      case APPROVE_PLANFORTHEDAY[RESET]:
        return false;
      case APPROVE_PLANFORTHEDAY[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isGetEmployeeAttendanceList = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE[SUCCESS]:
        return false
      case FETCH_ATTENDANCE[FAILURE]:
        return false
      case FETCH_ATTENDANCE[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isGetPlanForTheDayList = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_PLANFORTHEDAY[SUCCESS]:
        return true
      case GET_PLANFORTHEDAY[FAILURE]:
      case GET_PLANFORTHEDAY[REQUEST]:
      case GET_PLANFORTHEDAY[RESET]:  
        return false
      default:
        return state
    }
  }

  const isGetPlanForTheDayLoader = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_PLANFORTHEDAY[REQUEST]:
        return true
      case GET_PLANFORTHEDAY[FAILURE]:
      case GET_PLANFORTHEDAY[SUCCESS]:
      case GET_PLANFORTHEDAY[RESET]:  
        return false

      default:
        return state
    }
  }

  const isUpdatePlanForTheDayList = (state = false, action: Actions) => {
    switch (action.type) {
      case EDIT_PLANFORTHEDAY[SUCCESS]:
        return true
      case EDIT_PLANFORTHEDAY[FAILURE]:
      case EDIT_PLANFORTHEDAY[REQUEST]:
      case EDIT_PLANFORTHEDAY[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUpdateLoaderPlanForTheDayList = (state = false, action: Actions) => {
    switch (action.type) {
      case EDIT_PLANFORTHEDAY[REQUEST]:
        return true
      case EDIT_PLANFORTHEDAY[SUCCESS]:
      case EDIT_PLANFORTHEDAY[FAILURE]:
      case EDIT_PLANFORTHEDAY[RESET]:
        return false
      default:
        return state
    }
  }

  const isGetSingleIdsrList = (state = false, action: Actions) => {
    switch (action.type) {
      case GET_SINGLE_IDSR[SUCCESS]:
        return true
      case GET_SINGLE_IDSR[FAILURE]:
      case GET_SINGLE_IDSR[REQUEST]:
      case GET_SINGLE_IDSR[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUpdateSingleList = (state = false, action: Actions) => {
    switch (action.type) {
      case EDIT_SINGLE_IDSR[SUCCESS]:
        return true
      case EDIT_SINGLE_IDSR[FAILURE]:
      case EDIT_SINGLE_IDSR[REQUEST]:
      case EDIT_SINGLE_IDSR[RESET]:  
        return false
      default:
        return state
    }
  }

  const isUpdateLoaderIdsrList = (state = false, action: Actions) => {
    switch (action.type) {
      case EDIT_SINGLE_IDSR[REQUEST]:
        return true
      case EDIT_SINGLE_IDSR[SUCCESS]:
      case EDIT_SINGLE_IDSR[FAILURE]:
      case EDIT_SINGLE_IDSR[RESET]:
        return false
      default:
        return state
    }
  }


  const getAttendanceTimesheet = (state = [], action: Actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE_TIMESHEET[SUCCESS]:
        return action.payload
      case FETCH_ATTENDANCE_TIMESHEET[REQUEST]:
        return []
      default:
        return state
    }
  }

  const isCreatingRCA = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_RCA[SUCCESS]:
      case CREATE_RCA[FAILURE]:
        return false
      case CREATE_RCA[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isRCACreated = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_RCA[SUCCESS]:
        return true
      case CREATE_RCA[FAILURE]:
      case CREATE_RCA[REQUEST]:
      case CREATE_RCA[RESET]:
        return false
      default:
        return state
    }
  }
  const isCreatingIDSR = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_IDSR[SUCCESS]:
      case CREATE_IDSR[FAILURE]:
        return false
      case CREATE_IDSR[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isCreatingPlanForTheDay = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_PLAN_FOR_THE_DAY[SUCCESS]:
      case CREATE_PLAN_FOR_THE_DAY[FAILURE]:
          return false
      case CREATE_PLAN_FOR_THE_DAY[REQUEST]:
          return true
      default:
          return state
    }
  }

  const isFetchingIDSRs = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_IDSR[SUCCESS]:
        return false
      case FETCH_IDSR[FAILURE]:
      case FETCH_IDSR[REQUEST]:
        return true
      default:
        return state
    }
  }
  
  const isFetchingPlanForTheDay = (state = false, action: Actions) => {
    switch (action.type) {
      case FETCH_PLANS_FOR_THE_DAY[SUCCESS]:
        return false
      case FETCH_PLANS_FOR_THE_DAY[FAILURE]:
      case FETCH_PLANS_FOR_THE_DAY[REQUEST]:
        return true
      default:
        return state
    }
  }

  const isIDSRCreated = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_IDSR[SUCCESS]:
        return true
      case CREATE_IDSR[FAILURE]:
      case CREATE_IDSR[REQUEST]:
      case CREATE_IDSR[RESET]:
        return false
      default:
        return state
    }
  }

  const isPlanForTheDayCreated= (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_PLAN_FOR_THE_DAY[SUCCESS]:
        return true
      case CREATE_PLAN_FOR_THE_DAY[FAILURE]:
      case CREATE_PLAN_FOR_THE_DAY[REQUEST]:
      case CREATE_PLAN_FOR_THE_DAY[RESET]:
        return false
      default:
        return state
    }
  }

  const resetRCA = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_RCA[RESET]:
        return true
      case CREATE_RCA[SUCCESS]:
      case CREATE_RCA[FAILURE]:
      case CREATE_RCA[REQUEST]:
        return false
      default:
        return state
    }
  }
  const resetIDSR = (state = false, action: Actions) => {
    switch (action.type) {
      case CREATE_IDSR[RESET]:
        return true
      case CREATE_IDSR[SUCCESS]:
      case CREATE_IDSR[FAILURE]:
      case CREATE_IDSR[REQUEST]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isGetEmployeeAttendanceList,
    getAttendanceTimesheet,
    isCreatingRCA,
    isRCACreated,
    resetRCA,
    isCreatingIDSR,
    isIDSRCreated,
    isFetchingIDSRs,
    resetIDSR,
    isCreatingPlanForTheDay,
    isPlanForTheDayCreated,
    isFetchingPlanForTheDay,
    isCreateCommentForPlanForTheDayLoader,
    isCreateCommentForPlanForTheDaySuccess,
    isCreateCommentForTimesheetLoader,
    isCreateCommentForTimesheetSuccess,
    isApprovingPlanForTheDay,
    isApprovingPlanForTheDaySuccess,
    isGetPlanForTheDayList,
    isUpdatePlanForTheDayList,
    isUpdateLoaderPlanForTheDayList,
    isGetPlanForTheDayLoader,
    isGetSingleIdsrList,
    isUpdateSingleList,
    isUpdateLoaderIdsrList
  })
}

export default ui

export const getEmpAttendanceList = (state: RootState) => state.ui.employeeListAttendance
