import styled from 'styled-components'
import { Box, IconButton, TableRow, Tooltip, Typography } from '@mui/material'
import { StyledTableCell, StyledTableCellForMyTeam } from '../Common/CommonStyles'
import CheckIcon from '@mui/icons-material/Check'
import PendingIcon from '@mui/icons-material/Pending'
import CommentIcon from '@mui/icons-material/Comment'
import { ReactComponent as CheckedApprovedIcon } from '../../assets/images/checkApprovedIcon.svg'
import { ReactComponent as EditIcon } from '../../assets/images/editIconTimesheet.svg'

import './TooltipComment.scss'
import dayjs from 'dayjs'


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '15px',
  boxShadow: '0px 5px 2px #6c6c6c10',
  opacity: '1',
}))

const style = {
  rowInnerItem: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
    cursor: 'pointer',
  },
}

const TableRowContainer = (props: any) => {
  const {
    row,
    idx,
    onRowClick,
    statusType,
    selectedTab,
    setNewPlanDialog,
    isNewPlanDialogOpen,
    getPfpId,
    setPfpId,
    handleGetpfpIdForMngEdit,
  } = props

  const stripHtmlTags = (html: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = html

    let textContent = tempElement.textContent || tempElement.innerText || ''

    return textContent
  }

  const hasApprovedStatus = row.hasOwnProperty('approve_status')

  function htmlToText(html: string) {
    const temporaryElement = document.createElement('div')
    temporaryElement.innerHTML = html
    return temporaryElement.textContent || temporaryElement.innerText || ''
  }

  const leadTooltipContent = (data: any) => {
    return (
      <div style={{ padding: '8px', width: '150px' }}>
        <Typography fontWeight={500} fontSize={'13px'}>
          Comment:{' '}
        </Typography>
        <Typography marginTop={'5px'} fontSize={'12px'}>
          {data}
        </Typography>
      </div>
    )
  }
  const convertMinutesToHours = (minutes: number) => {
    const hours = (minutes / 60).toFixed(1)
    return `${hours} hrs`
  }

  const handleEditPlanForTheDay = (event: any, data: any) => {
    handleGetpfpIdForMngEdit({ sender: data.sender_mail, cc: data.cc })
    const dataPfp = data.id
    event.preventDefault()
    event.stopPropagation()
    // setCheckPfpForm('edit')
    if (data.approve_status !== 1) {
      setPfpId(dataPfp)
      setNewPlanDialog(true)
    }
  }

  return hasApprovedStatus ? (
    <StyledTableRow id='body' key={idx} sx={style.border} onClick={() => onRowClick(row)}>
      <StyledTableCellForMyTeam>
        {row.name ? `${row.name}` : 'N/A'}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam>{row.subject ? row.subject : 'N/A'}</StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam>
        {row.project_name === 'Other' ? row.other_project_name : row.project_name}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam>
        {row.mandate_type_id ? statusType.get(row.mandate_type_id) : 'N/A'}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        {`${row.time_spent} mins (${convertMinutesToHours(row.time_spent)})`}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        <Box display={'flex'} justifyContent={'center'} sx={{ marginTop: '6px' }}>
          {row.approve_status === 1 ? (
            <Tooltip title={'Approved'} arrow>
              <CheckedApprovedIcon
                fontSize='small'
                style={{ width: '26px', height: '26px', textAlign: 'center' }}
              />
            </Tooltip>
          ) : (
            <Tooltip title={'Pending'} arrow>
              <PendingIcon
                style={{
                  color: 'orange',
                  fontSize: '30px',
                  textAlign: 'center',
                  marginLeft: '-2px',
                }}
              />
            </Tooltip>
          )}
        </Box>
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        {dayjs(row.submission_time).format('hh:mm A')}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        <Tooltip
          placement='left'
          classes={{
            tooltip: 'custom-tooltip',
            arrow: 'custom-tooltip-arrow',
          }}
          title={row.comment ? leadTooltipContent(htmlToText(row.comment)) : ''}
          arrow
        >
          <IconButton
            sx={{
              width: '40px',
              opacity: row?.comment ? 1 : 0.5,
              color: '#483F3F',
              cursor: row?.comment ? 'pointer' : 'not-allowed',
              marginTop: '6px',
            }}
            disableRipple={row?.comment}
          >
            {' '}
            <CommentIcon />
          </IconButton>
        </Tooltip>
      </StyledTableCellForMyTeam>
      {selectedTab !== 1 && (
        <StyledTableCellForMyTeam align='left'>
          <Tooltip title={'Edit'} arrow>
            <EditIcon
              style={{
                opacity: row.approve_status === 1 ? '0.5' : '1',
                cursor: row.approve_status === 1 ? 'not-allowed' : 'pointer'
              }}
              onClick={(event) => handleEditPlanForTheDay(event, row)}
              width={'27px'}
              height={'27px'}
            />
          </Tooltip>
        </StyledTableCellForMyTeam>
      )}
    </StyledTableRow>
  ) : (
    <StyledTableRow id='body' key={idx} sx={style.border} onClick={() => onRowClick(row)}>
      <StyledTableCellForMyTeam>
      {row.name ? `${row.name}` : 'N/A'}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam>{row.subject ? row.subject : 'N/A'}</StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam>
        {row.project_name === 'Other' ? row.other_project_name : row.project_name}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam>
        {row.mandate_type_id ? statusType.get(row.mandate_type_id) : 'N/A'}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        {`${row.time_spent} mins (${convertMinutesToHours(row.time_spent)})`}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        {dayjs(row.submission_time).format('hh:mm A')}
      </StyledTableCellForMyTeam>
      <StyledTableCellForMyTeam align='left'>
        <Tooltip
          placement='left'
          classes={{
            tooltip: 'custom-tooltip',
            arrow: 'custom-tooltip-arrow',
          }}
          title={row.comment ? leadTooltipContent(htmlToText(row.comment)) : ''}
        >
          <IconButton
            sx={{
              width: '40px',
              opacity: row?.comment ? 1 : 0.5,
              color: '#483F3F',
              cursor: row?.comment ? 'pointer' : 'not-allowed',
            }}
            disableRipple={row?.comment}
          >
            {' '}
            <CommentIcon />
          </IconButton>
        </Tooltip>
      </StyledTableCellForMyTeam>
      {selectedTab !== 1 && (
        <StyledTableCellForMyTeam align='left'>
          <Tooltip title={'Edit'} arrow>
            <EditIcon
              style={{
                opacity: row.approve_status === 1 ? '0.5' : '1',
                cursor: row.approve_status === 1 ? 'not-allowed' : 'pointer',
              }}
              onClick={(event) => handleEditPlanForTheDay(event, row)}
              width={'27px'}
              height={'27px'}
            />
          </Tooltip>
        </StyledTableCellForMyTeam>
      )}
    </StyledTableRow>
  )
}

export default TableRowContainer
