import React from 'react'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import { HeaderHeadingDelayedTask } from '../Common/CommonStyles'
import CloseIcon from '@mui/icons-material/Close'

const DelayedTaskRowData = (props: any) => {
  const { open, rowData, setOpen } = props

  const handleOnClose = () => {
    setOpen(false)
  }

  // Function to strip HTML tags
  const stripHtmlTags = (html: any) => {
    // Create a temporary div element to safely manipulate the HTML content
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html

    // Extract and return the text content, which preserves list formatting
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%', // Custom width as a percentage or pixels
          maxWidth: '900px', // Optional: max width in pixels
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <HeaderHeadingDelayedTask>Delayed Task Descripton</HeaderHeadingDelayedTask>
        <IconButton
          aria-label='close'
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            marginTop: '2px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'left',
          padding: '10px',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
              }}
            >
              <strong>Emp Name</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
              }}
            >
              <strong>Project Name</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
              }}
            >
              <strong>Mandate Type</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
              }}
            >
              <strong>Task Description</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
              }}
            >
              <strong>Priority</strong>
            </th>
            <th
              style={{
                border: '1px solid #dddddd',
                padding: '8px',
                backgroundColor: '#f2f2f2',
                whiteSpace: 'nowrap',
              }}
            >
              <strong>Time Spent (mins)</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
              {rowData?.empName || 'NA'}
            </td>
            <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
              {rowData?.projectName || 'NA'}
            </td>
            <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
              {rowData?.mandateName || 'NA'}
            </td>
            <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
              {stripHtmlTags(rowData?.taskDescription) || 'NA'}
            </td>
            <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
              {rowData?.priority || 'NA'}
            </td>
            <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
              {rowData?.timeSpend || 'NA'}
            </td>
          </tr>
        </tbody>
      </table>
    </Dialog>
  )
}

export default DelayedTaskRowData
