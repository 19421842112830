import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions} from '../../actions/Types'
import { RootState } from '../../configureStore'
import { GET_SINGLE_IDSR, GET_PLANFORTHEDAY, GET_COMMENT_PLANFORTHEDAY, GET_COMMENT_TIMESHEET, REQUEST, SUCCESS } from '../../actions/actiontypes'

const { FETCH_ATTENDANCE, FETCH_ATTENDANCE_TIMESHEET } = actionTypes

const entity = () => {

  const getEmployeeAttendance = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE[SUCCESS]:
        return action.payload
      case FETCH_ATTENDANCE[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getPlanForTheDayData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_PLANFORTHEDAY[SUCCESS]:
        return action.payload
      case GET_PLANFORTHEDAY[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getSingleIdsrData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_SINGLE_IDSR[SUCCESS]:
        return action.payload
      case GET_SINGLE_IDSR[REQUEST]:
        return []

      default:
        return state
    }
  }

  const getCommentPlanForTheDayReducer = (state = [], action: actions) => {
    switch (action.type) {
      case GET_COMMENT_PLANFORTHEDAY[SUCCESS]:
        return action.payload
      case GET_COMMENT_PLANFORTHEDAY[REQUEST]:
        return []

      default:
        return state
    }
  }


  const getCommentTimesheetReducer = (state = [], action: actions) => {
    switch (action.type) {
      case GET_COMMENT_TIMESHEET[SUCCESS]:
        return action.payload
      case GET_COMMENT_TIMESHEET[REQUEST]:
        return []

      default:
        return state
    }
  }


  const getAttendanceTimesheet = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ATTENDANCE_TIMESHEET[SUCCESS]:
        return action.payload
      case FETCH_ATTENDANCE_TIMESHEET[REQUEST]:
        return []

      default:
        return state
    }
  }

  return combineReducers({
    getEmployeeAttendance,
    getAttendanceTimesheet,
    getCommentPlanForTheDayReducer,
    getCommentTimesheetReducer,
    getPlanForTheDayData,
    getSingleIdsrData
  })
}

export default entity

export const getAllAttendance = (state: RootState) => state.entities.EmployeeAttendanceRequest
