import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {  GET_SINGLE_IDSR, EDIT_SINGLE_IDSR,GET_PLANFORTHEDAY, EDIT_PLANFORTHEDAY, ADD_COMMENT_PLANFORTHEDAY, ADD_COMMENT_TIMESHEET, APPROVE_PLANFORTHEDAY, FETCH_ATTENDANCE, FETCH_ATTENDANCE_TIMESHEET, GET_COMMENT_PLANFORTHEDAY, GET_COMMENT_TIMESHEET } from '../actions/actiontypes'
import { debugPort } from 'process'
const { REQUEST } = actionTypes

function* getEmployeeAttendance(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAttendance,
      data,
    )
    yield sendPayload(apiResponse, FETCH_ATTENDANCE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ATTENDANCE)
  }
}

function* getPlanForTheDay(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getPlanForTheDayApiCall,
      data,
    )
    yield sendPayload(apiResponse, GET_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, GET_PLANFORTHEDAY)
  }
}

function* updatePlanForTheDay(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updatePlanForTheDayApiCall,
      data,
    )
    yield sendPayload(apiResponse, EDIT_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, EDIT_PLANFORTHEDAY)
  }
}

function* getSingleIdsr(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getSingleIdsrApiCall,
      data,
    )
    yield sendPayload(apiResponse, GET_SINGLE_IDSR)
  } catch (e) {
    yield sendPayloadFailure(e, GET_SINGLE_IDSR)
  }
}

function* updateSingleIdsr(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateSingleIdsrApiCall,
      data,
    )
    yield sendPayload(apiResponse, EDIT_SINGLE_IDSR)
  } catch (e) {
    yield sendPayloadFailure(e, EDIT_SINGLE_IDSR)
  }
}

function* getCommentTimesheetSaga(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCommentTimesheet,
      data,
    )
    yield sendPayload(apiResponse, GET_COMMENT_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, GET_COMMENT_TIMESHEET)
  }
}

function* getCommentPlanForTheDaySaga(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCommentPlanForTheday,
      data,
    )
    yield sendPayload(apiResponse,GET_COMMENT_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, GET_COMMENT_PLANFORTHEDAY)
  }
}

function* getAttendanceTimesheet(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAttendanceTimesheet,
      data.userId,
    )
    yield sendPayload(apiResponse, FETCH_ATTENDANCE_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ATTENDANCE_TIMESHEET)
  }
}

function* createCommentPlanForTheDay(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createCommentPlanForTheDay,
      data
    )
    yield sendPayload(apiResponse, ADD_COMMENT_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_COMMENT_PLANFORTHEDAY)
  }
}

function* createCommentTimesheet(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createCommentTimesheet,
      data
    )
    yield sendPayload(apiResponse, ADD_COMMENT_TIMESHEET)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_COMMENT_TIMESHEET)
  }
}

function* approvePlanForTheDaySaga(data: any) {
  
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.approvePlanForTheDayAPI,
      data
    )
    yield sendPayload(apiResponse, APPROVE_PLANFORTHEDAY)
  } catch (e) {
    yield sendPayloadFailure(e, APPROVE_PLANFORTHEDAY)
  }
}

export const sagas = {
  watchEmployeeAttendance: takeLatest(actionTypes.FETCH_ATTENDANCE[REQUEST], getEmployeeAttendance),
  watchGetCommentTimesheet: takeLatest(actionTypes.GET_COMMENT_TIMESHEET[REQUEST], getCommentTimesheetSaga),
  watchGetCommentPlanForTheDay: takeLatest(actionTypes.GET_COMMENT_PLANFORTHEDAY[REQUEST], getCommentPlanForTheDaySaga),
  watchAddCommentPlanForTheDay: takeLatest(actionTypes.ADD_COMMENT_PLANFORTHEDAY[REQUEST], createCommentPlanForTheDay),
  watchAddCommentTimesheet: takeLatest(actionTypes.ADD_COMMENT_TIMESHEET[REQUEST], createCommentTimesheet),
  watchApprovePlanForTheDay: takeLatest(actionTypes.APPROVE_PLANFORTHEDAY[REQUEST], approvePlanForTheDaySaga),
  watchAttendanceTimesheet: takeLatest(
    actionTypes.FETCH_ATTENDANCE_TIMESHEET[REQUEST],
    getAttendanceTimesheet,
  ),
  watchGetPlanForTheDay: takeLatest(actionTypes.GET_PLANFORTHEDAY[REQUEST], getPlanForTheDay),
  watchUpdatePlanForTheDay: takeLatest(actionTypes.EDIT_PLANFORTHEDAY[REQUEST], updatePlanForTheDay),
  watchGetSingleIdsr: takeLatest(actionTypes.GET_SINGLE_IDSR[REQUEST], getSingleIdsr),
  watchUpdateSingleIdsr: takeLatest(actionTypes.EDIT_SINGLE_IDSR[REQUEST], updateSingleIdsr),
}
