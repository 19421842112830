import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  FETCH_ASSET,
  FETCH_BACKINFO,
  FETCH_BASICINFO,
  FETCH_HOLIDAYS,
  FETCH_LEAVE_DETAILS,
  FETCH_MARITIAL_STATUS,
  FETCH_SR,
  FETCH_USERS,
  FETCH_DEPARTMENT_LIST,
  FETCH_ISSUE_TYPE_LIST,
  USER_INFO,
  HOME_PAGE_INFO,
  FETCH_USER_BY_ID,
  FETCH_REF_BY_ID,
  FETCH_DRS_DATA,
  FETCH_SUB_DRS_DATA,
  GET_LEAVE_TIMESHEET,
  GET_LEAVE_DATA,
  ACCEPT_LEAVE_DATA,
  REJECT_LEAVE_DATA,
  FETCH_PROJECT_DATA,
  RESET,
  FETCH_HIGHLIGHTS,
  // FETCH_ASSIGNED_SR,
  FETCH_ASSIGNED_REQUEST,
  FETCH_UPDATED_SERVICE_REQUEST,
  FETCH_DOWNLOADABLE_URL,
  MANAGER_VIEW_DATA,
  FETCH_DOC_URL,
  COMPANY_LOGO,
  COMPANY_ID,
  GET_CLIENT_MEMBER,
  GET_TEAM_MANDATES,
  GET_DELAYED_GRAPH,
  GET_DELAYED_TABLE,
} from '../../actions/actiontypes'
import { defaultValue, ILeavesAccrualReport } from '../../models/leaves-accrual-report.model'

const { SUCCESS, REQUEST, GET_USER_DATA } = actionTypes

const entity = () => {
  const getUserData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_USERS[SUCCESS]:
        return action.payload
      case FETCH_USERS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getClientMembersData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_CLIENT_MEMBER[SUCCESS]:
        return action.payload
      case GET_CLIENT_MEMBER[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getTeamMandatesData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_TEAM_MANDATES[SUCCESS]:
        return action.payload
      case GET_TEAM_MANDATES[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDelayedTableData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_DELAYED_TABLE[SUCCESS]:
        return action.payload
      case GET_DELAYED_TABLE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDelayedGraphData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_DELAYED_GRAPH[SUCCESS]:
        return action.payload
      case GET_DELAYED_GRAPH[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getBackInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_BACKINFO[SUCCESS]:
        return action.payload
      case FETCH_BACKINFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getHolidaysData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_HOLIDAYS[SUCCESS]:
        return action.payload
      case FETCH_HOLIDAYS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getHighlightsData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_HIGHLIGHTS[SUCCESS]:
        return action.payload
      case FETCH_HIGHLIGHTS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getAssetData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ASSET[SUCCESS]:
        return action.payload
      case FETCH_ASSET[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getBasicInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_BASICINFO[SUCCESS]:
        return action.payload
      case FETCH_BASICINFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getSRData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_SR[SUCCESS]:
        return action.payload
      case FETCH_SR[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDrsData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DRS_DATA[SUCCESS]:
        return action.payload
      case FETCH_DRS_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getSubDrsData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_SUB_DRS_DATA[SUCCESS]:
        return action.payload
      case FETCH_SUB_DRS_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getMaritalStatusData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_MARITIAL_STATUS[SUCCESS]:
        return action.payload
      case FETCH_MARITIAL_STATUS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLeaveDetails = (state: ILeavesAccrualReport = defaultValue, action: actions) => {
    switch (action.type) {
      case FETCH_LEAVE_DETAILS[SUCCESS]:
        return action.payload as ILeavesAccrualReport
      case FETCH_LEAVE_DETAILS[REQUEST]:
        return defaultValue
      default:
        return state
    }
  }

  const getDepartmentList = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_DEPARTMENT_LIST[SUCCESS]:
        return action.payload
      case FETCH_DEPARTMENT_LIST[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLeaveTimesheets = (state = [], action: actions) => {
    switch (action.type) {
      case GET_LEAVE_TIMESHEET[SUCCESS]:
        return action.payload
      case GET_LEAVE_TIMESHEET[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getLeaveData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_LEAVE_DATA[SUCCESS]:
        return action.payload
      case GET_LEAVE_DATA[REQUEST]:
      case GET_LEAVE_DATA[RESET]:
        return []
      default:
        return state
    }
  }

  const acceptLeaveData = (state = [], action: actions) => {
    switch (action.type) {
      case ACCEPT_LEAVE_DATA[SUCCESS]:
        return action.payload
      case ACCEPT_LEAVE_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const rejectLeaveData = (state = [], action: actions) => {
    switch (action.type) {
      case REJECT_LEAVE_DATA[SUCCESS]:
        return action.payload
      case REJECT_LEAVE_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getProjectData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_PROJECT_DATA[SUCCESS]:
        return action.payload
      case FETCH_PROJECT_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getIssueTypeList = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ISSUE_TYPE_LIST[SUCCESS]:
        return action.payload
      case FETCH_ISSUE_TYPE_LIST[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getManagerDataById = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_USER_BY_ID[SUCCESS]:
        return action.payload
      case FETCH_USER_BY_ID[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getRefDataById = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_REF_BY_ID[SUCCESS]:
        return action.payload
      case FETCH_REF_BY_ID[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getUserDetails = (state = [], action: actions) => {
    switch (action.type) {
      case USER_INFO[SUCCESS]:
        return action.payload
      case USER_INFO[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getHomePageDetails = (state = [], action: actions) => {
    switch (action.type) {
      case HOME_PAGE_INFO[SUCCESS]:
        return action.payload
      case HOME_PAGE_INFO[REQUEST]:
        return []
      default:
        return state
    }
  }
  const getAssignedRequest = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ASSIGNED_REQUEST[SUCCESS]:
        return action.payload
      case FETCH_ASSIGNED_REQUEST[REQUEST]:
        return []
      default:
        return state
    }
  }
  const createUpdatedServiceRequestData = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_UPDATED_SERVICE_REQUEST[SUCCESS]:
        return action.payload
      case FETCH_UPDATED_SERVICE_REQUEST[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDownloadableURL = (state = '', action: actions) => {
    switch (action.type) {
      case FETCH_DOWNLOADABLE_URL[SUCCESS]:
        return action.payload
      case FETCH_DOWNLOADABLE_URL[REQUEST]:
        return ''
      default:
        return state
    }
  }

  const getManagerViewData = (state = [], action: actions) => {
    switch (action.type) {
      case MANAGER_VIEW_DATA[SUCCESS]:
        return action.payload
      case MANAGER_VIEW_DATA[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getDocURL = (state = '', action: actions) => {
    switch (action.type) {
      case FETCH_DOC_URL[SUCCESS]:
        return action.payload
      case FETCH_DOC_URL[REQUEST]:
        return ''
      default:
        return state
    }
  }

  const getLogo = (state = '', action: actions) => {
    switch (action.type) {
      case COMPANY_LOGO[SUCCESS]:
        return action.payload
      case COMPANY_LOGO[REQUEST]:
        return ''
      default:
        return state
    }
  }

  const getCompanyId = (state = [], action: actions) => {
    switch (action.type) {
      case COMPANY_ID[SUCCESS]:
        return action.payload
      case COMPANY_ID[REQUEST]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getUserData,
    getBackInfoData,
    getHolidaysData,
    getHighlightsData,
    getAssetData,
    getBasicInfoData,
    getSRData,
    // getAssignedSRData,
    getMaritalStatusData,
    getLeaveDetails,
    // createServiceRequest,
    getDepartmentList,
    getIssueTypeList,
    getUserDetails,
    getHomePageDetails,
    getManagerDataById,
    getRefDataById,
    getDrsData,
    getSubDrsData,
    getLeaveTimesheets,
    getLeaveData,
    acceptLeaveData,
    rejectLeaveData,
    getProjectData,
    getAssignedRequest,
    createUpdatedServiceRequestData,
    getDownloadableURL,
    getManagerViewData,
    getDocURL,
    getLogo,
    getCompanyId,
    getClientMembersData,
    getTeamMandatesData,
    getDelayedGraphData,
    getDelayedTableData
  })
}

export default entity

export const getDashboard = (state: RootState) => state.entities.dashboard
